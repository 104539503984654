import React from "react";
import closeImg from "../../asset/img/close.svg";
import {NavLink} from "react-router-dom";

type MobileSideNavProps = {
    show: boolean,
    handleClick: () => void;
}


const MobileSideNav = ({show, handleClick}: MobileSideNavProps) => {

    return (
        <div className={show ? 'aside_container active' : 'aside_container'}>
            <div className='aside_header'>
                <img onClick={handleClick} src={closeImg}/>
            </div>
            <div className='aside_nav_link'>
                <ul>
                    <li>
                        <NavLink
                            exact
                            to="/introduce"
                            onClick={handleClick}>
                            브랜드 스토리
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to="/list"
                            onClick={handleClick}>
                            매트리스
                        </NavLink>
                    </li>
                    <li><NavLink
                        exact
                        to="/showRoom"
                        onClick={handleClick}>
                        매장안내
                    </NavLink></li>
                    <li><NavLink
                        exact
                        to="/customer"
                        onClick={handleClick}>
                        고객센터
                    </NavLink></li>
                </ul>
            </div>
            <div className='aside_nav_sub_link'>
                <ul>
                    <li>
                        <NavLink
                            exact
                            to={
                                {
                                    pathname: `/customer`,
                                    state: {tabNo: '1'}
                                }
                            }
                            onClick={handleClick}>
                            공지사항
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            exact
                            to={
                                {
                                    pathname: `/customer`,
                                    state: {tabNo: '2'}
                                }
                            }
                            onClick={handleClick}>
                            자주묻는질문
                        </NavLink>
                    </li>
                </ul>
            </div>
        </div>
    )
}

MobileSideNav.defaultProps = {
    show: false
}

export default MobileSideNav;

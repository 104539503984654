import React, {useState} from "react";
import mattress from "../../data/mattress";
import {Col, Row} from "reactstrap";
import arrow from '../../asset/img/arrow.png';
import double_arrow from '../../asset/img/double_arrow.png';
import top_img from '../../asset/img/list/list_main_img.png'
import {Link} from "react-router-dom";


const List = () => {
    const [isListHover, setIsListHover] = useState(0);

    return (
        <div className='list_container'>
            <div className='list_top_img_wrapper'>
                <div className='list_top_back'/>
                <div className='list_top_label'>
                    <h2>
                        고객님의 수면 건강을 책임지겠습니다.
                    </h2>
                    <span>클라우드 매직 매트리스로 편안하고 안락한 잠자리를 느껴보세요.</span>
                </div>
                <img src={top_img}/>
            </div>

            <div className='container'>

                <Row className='list_header_wrapper'>
                    <Col xs={12} className='text-center'>
                        <h3>매트리스</h3>
                    </Col>
                    <Col xs={12}>
                        <ul className='d-flex flex-row list_sort_btn pt-2 justify-content-end'>
                            <li><span>최신순</span></li>
                            <li><span>|</span></li>
                            <li><span>판매순위순</span></li>
                            <li><span>|</span></li>
                            <li><span>낮은가격순</span></li>
                            <li><span>|</span></li>
                            <li><span>높은 가격순</span></li>
                        </ul>
                    </Col>
                </Row>
                <hr/>
                <div className='list_wrapper'>
                    {mattress.map((item, index) => {
                        return (
                            <div key={index} className='list_item_wrapper'>
                                <Link to={`/detail/${index+1}`}>
                                    <div className='list_img_wrapper'>
                                        <img
                                            onMouseEnter={() => setIsListHover(index + 1)}
                                            onMouseLeave={() => setIsListHover(0)}
                                            src={isListHover !== index + 1 ? item.list_img[0] : item.list_img[1]}/>
                                    </div>
                                    <div className='list_info_wrapper'>
                                        <Row className='g-0'>
                                            <Col xs={12} className='info_brand'>
                                        <span>
                                        Cloud Magic
                                            </span>
                                            </Col>
                                            <Col xs={12} className='info_title'>
                                        <span>
                                        {item.title}
                                            </span>
                                            </Col>
                                            <Col xs={12} className='info_size'>
                                                <span>SS</span>
                                                <span>Q</span>
                                                <span>K</span>
                                            </Col>
                                            <Col xs={12} className='info_price'>
                                                <div className='info_price_wrapper'>
                                        <span>
                                            ₩{item.price['Q']}
                                        </span>
                                                    <span className='first_price'>
                                            ₩{item.firstPrice}
                                        </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Link>
                            </div>
                        )
                    })}

                </div>
                <div className='pagination_wrapper'>
                    <button className='left_page_btn'><img src={double_arrow}/></button>
                    <button className='left_page_btn'><img src={arrow}/></button>
                    <button><span>1</span></button>
                    <button><img src={arrow}/></button>
                    <button><img src={double_arrow}/></button>
                </div>
            </div>
        </div>
    )
}

export default List;

import React from "react";
import {Link} from 'react-router-dom'

const Footer = () => {


    return (
        <div className='footer_wrapepr'>
            <div className='container'>
                <div className='footer_top_wrapper'>
                    <ul className='footer_list_wrapper'>
                        <li>BRAND
                            <ul className='footer_list_in_wrapper'>
                                <li>
                                    <Link to={'/introduce'}>
                                        BRAND STORY
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>SHOP
                            <ul className='footer_list_in_wrapper'>
                                <li>
                                    <Link to={'/list'}>
                                        매트리스
                                    </Link>
                                </li>
                                <li>
                                    <Link to={'/showRoom'}>
                                        매장안내
                                    </Link>
                                </li>

                            </ul>
                        </li>
                        <li>CS CENTER
                            <ul className='footer_list_in_wrapper'>
                                <li>
                                    {/*Tel 010-6321-2729*/}
                                </li>
                                <li>
                                    평일,주말10시 ~ 20시 (점심 13시 ~ 14시)
                                </li>
                            </ul>
                        </li>
                        <li>BANK INFO
                            <ul className='footer_list_in_wrapper'>
                                <li>
                                    우리은행 1005-204-044467
                                </li>
                                <li>
                                    클라우드매직(김구남)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className='footer_bottom_wrapper'>
                    <div className='footer_info_wrapper'>
                        <label className='mb-3'>COMPANY INFO</label>
                        {/*<p>COMPANY 클라우드매직 CEO 김구남 TEL 010-6321-2729 사업자번호 208-25-20660</p>*/}
                        <p>COMPANY 클라우드매직 CEO 김구남 사업자번호 208-25-20660</p>
                        <p>통신판매업 제2021-경기포천-0578호 <a href='https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2082520660'>[사업자정보확인]</a></p>
                        <p>ADDRESS 경기도 포천시 신북면 호국로 2274-27 클라우드매직</p>
                        <p>RETURN & EXCHANGE 경기도 포천시 메나리길 110-34 좌측 3번동 대형터닝 클라우드매직</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;

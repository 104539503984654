import mainLogo from '../../asset/img/landing/main_logo.svg'
import mainLogo_black from '../../asset/img/landing/main_logo_black.svg'

const windowHeight = window.innerHeight;
export const landingScroll = () => {
    const scrollTop = window.scrollY;
    let alpha = (scrollTop / windowHeight) * 2;
    alpha >= 1 && (alpha = 1);
    if (alpha < 0.7) {

        // document.getElementById('main_logo_img').src = mainLogo_black;
        document.getElementsByClassName('nav-links')[0].style.color = 'white'
        document.getElementsByClassName('nav-links')[1].style.color = 'white'
        document.getElementsByClassName('nav-links')[2].style.color = 'white'
    } else {
        // document.getElementById('main_logo_img').src = mainLogo_black;
        document.getElementsByClassName('nav-links')[0].style.color = 'black'
        document.getElementsByClassName('nav-links')[1].style.color = 'black'
        document.getElementsByClassName('nav-links')[2].style.color = 'black'
    }
    if (document.getElementsByClassName('navbar_more_wrapper').length !== 0) {
        if (alpha < 1) {
            // document.getElementById('landing_navbar_more_wrapper').classList.add('active');
            // document.getElementsByClassName('navbar_more_wrapper')[0].style.display = 'none'
        } else {
            // document.getElementsByClassName('navbar_more_wrapper')[0].style.display = 'block'
            // document.getElementById('landing_navbar_more_wrapper').classList.remove('active');
        }
    }

    if (document.getElementsByClassName('landing_nav').length !== 0)
        document.getElementsByClassName('landing_nav')[0].style.backgroundColor = `rgba(255,255,255,${alpha})`;
};

import React, {useEffect} from "react";
import {Redirect, Switch} from "react-router-dom";
import {RouteIf} from "../hoc/RouteIf";
import Landing from "../components/landing/Landing";
import List from "../components/list/List";
import BrandStory from "../components/brand/BrandStory";
import ShowRoom from "../components/showRoom/ShowRoom";
import Customer from "../components/customer/Customer";
import Notice from "../components/customer/Notice";
import Detail from "../components/detail/Detail";
import {useLocation} from "react-router-dom";

const DashboardLayout = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <Switch>
            <RouteIf exact path="/" option={null} component={Landing}/>
            {/*<RouteIf path="/login" option={false} component={Login}/>*/}
            {/*<RouteIf path="/register" option={false} component={Register}/>*/}
            <RouteIf path="/list" option={null} component={List}/>
            <RouteIf path="/introduce" option={null} component={BrandStory}/>
            <RouteIf path="/showRoom" option={null} component={ShowRoom}/>
            <RouteIf path="/customer" option={null} component={Customer}/>
            <RouteIf path="/notice" option={null} component={Notice}/>
            <RouteIf path="/detail/:no" option={null} component={Detail}/>
            <RouteIf option={null} component={() => <Redirect to='/errors/404'/>}/>
        </Switch>
    )
}

export default DashboardLayout;

import React from "react";
import {Provider} from 'react-redux';
import store from './store'
import Layout from "./layouts/Layout";
import CloudMagicProvider from "./CloudMagicProvider";
import axios from "axios";
axios.defaults.withCredentials = true;
function App() {
    return (
        <Provider store={store}>
            <CloudMagicProvider>
                <Layout/>
            </CloudMagicProvider>
        </Provider>
    );
}

export default App;

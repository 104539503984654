import React, {useContext, useEffect} from 'react';
import {BrowserRouter as Router, Switch, Redirect} from 'react-router-dom';
import {RouteIf} from "../hoc/RouteIf";
import Landing from '../components/landing/Landing'
import NavbarTop from "../components/nav/NavbarTop";
import Footer from "../components/nav/Footer";
import TermModal from "../components/common/TermModal";
import CloudMagicContext from "../context/Context";

import DashboardLayout from "./DashboardLayout";

const Layout = () => {
    const {termModal, setTermModal, termSeq} = useContext(CloudMagicContext);

    useEffect(() => {
        // AuthBasicLayout.preload();
        // WizardLayout.preload();
    }, []);

    return (
        <>
            <Router fallback={<span/>}>
                <NavbarTop/>
                <DashboardLayout/>
                <Footer/>
                <TermModal setModal={setTermModal}
                           modal={termModal}
                           seq={termSeq}/>
            </Router>

        </>
    );
};

export default Layout;

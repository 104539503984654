import React, {useState} from "react";
import section_img_1 from '../../asset/img/landing/best_item/landing_section_img_1.png';
import section_img_2 from '../../asset/img/landing/best_item/landing_section_img_3.png';
import section_img_3 from '../../asset/img/landing/best_item/landing_section_img_2.png';
import section_img_4 from '../../asset/img/landing/best_item/landing_section_img_4.png';
import plus_logo from '../../asset/img/landing/best_item/add.svg';
import {useHistory} from "react-router-dom";

const LandingBestItem = () => {
    const history = useHistory();
    const [hoverImage, setHoverImage] = useState(0);
    return (
        <section className='landing_best_item_wrapper'>
            <ul className='stretcher'>
                <li className={hoverImage === 1 ? 'stretcher-item active' : hoverImage === 0 ? 'stretcher-item' : 'stretcher-item inactive'}
                    onMouseEnter={() => setHoverImage(1)}
                    onMouseLeave={() => setHoverImage(0)}
                    style={{backgroundImage: `url(${section_img_1})`}}
                    onClick={() => history.push('/detail/1')}>
                    <figure>
                        <h4>앨리스 포켓 매트리스</h4>
                        {/*<figcaption>매트리스 설명</figcaption>*/}
                    </figure>
                </li>
                <li className={hoverImage === 2 ? 'stretcher-item active' : hoverImage === 0 ? 'stretcher-item' : 'stretcher-item inactive'}
                    onMouseEnter={() => setHoverImage(2)}
                    onMouseLeave={() => setHoverImage(0)}
                    style={{backgroundImage: `url(${section_img_2})`}}
                    onClick={() => history.push('/detail/4')}>
                    <figure>
                        <h4>졸리 메모리폼 매직 매트리스</h4>
                        {/*<figcaption>매트리스 설명</figcaption>*/}
                    </figure>
                </li>
                <li className={hoverImage === 3 ? 'stretcher-item active' : hoverImage === 0 ? 'stretcher-item' : 'stretcher-item inactive'}
                    onMouseEnter={() => setHoverImage(3)}
                    onMouseLeave={() => setHoverImage(0)}
                    style={{backgroundImage: `url(${section_img_3})`}}
                    onClick={() => history.push('/detail/2')}>
                    <figure>
                        <h4>알란 메모리폼 매직 매트리스</h4>
                        {/*<figcaption>매트리스 설명</figcaption>*/}
                    </figure>
                </li>
                <li className={hoverImage === 4 ? 'stretcher-item active' : hoverImage === 0 ? 'stretcher-item' : 'stretcher-item inactive'}
                    onMouseEnter={() => setHoverImage(4)}
                    onMouseLeave={() => setHoverImage(0)}
                    style={{backgroundImage: `url(${section_img_4})`}}
                    onClick={() => history.push('/detail/6')}>
                    <figure>
                        <h4>로즈 메모리폼 매직 매트리스</h4>
                        {/*<figcaption>매트리스 설명</figcaption>*/}
                    </figure>
                </li>
                <li className={hoverImage === 5 ? 'more active' : 'more'} onMouseEnter={() => setHoverImage(5)} onMouseLeave={() => setHoverImage(0)}>
                    <div className='plus_logo' onClick={() => history.push('/list')}>
                        {hoverImage === 5 ?
                            <h4>더보기</h4>
                            :
                            <img src={plus_logo}/>
                        }

                    </div>
                </li>
            </ul>
        </section>
    )
}


export default LandingBestItem;

import React from "react";
import Slider from "react-slick";
import slick_img_1 from '../../asset/img/landing/banner1.png';
import slick_img_2 from '../../asset/img/landing/banner2.png';
import {Link} from 'react-router-dom';

const LandingSlick = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };
    return (
        <div className='container landing_slick_wrapper'>
            <Slider {...settings}>
                <div>
                    <Link to={'/detail/2'}>
                        <img src={slick_img_1}/>
                    </Link>
                </div>
                <div>
                    <Link to={'/list'}>
                        <img src={slick_img_2}/>
                    </Link>
                </div>
            </Slider>
        </div>
    )
}

export default LandingSlick;

import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import testImg from '../../asset/img/landing/landing_new_img2.svg'
import mainImg1 from '../../asset/img/landing/main_landing_img_1.png';
import mainImg2 from '../../asset/img/landing/main_landing_img_2.png';
import mattress from "../../data/mattress";

function DetailCarousel({detailNo}) {
    var items = [
        {
            img: mainImg1
        },
        {
            img: mainImg2
        }
    ]

    return (
        <div className=''>
            <Carousel
                autoPlay={true}
                interval={5000}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
            >
                {
                    mattress[detailNo].detail_mobile_img.map((img, i) =>
                        <img key={i} src={img} style={{width: '100%', height: '100%'}}/>
                    )
                }
            </Carousel>
        </div>
    )
}

export default DetailCarousel;

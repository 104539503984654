export default [
    {
        index: '1',
        date: '2021-07-09',
        title: 'cloud magic 브랜드 런칭',
        content: '2021년 08월 01일\n' +
            '‘클라우드 매직’ 매트리스가 여러분을 찾아갑니다.\n' +
            '    많은 관심과 응원 부탁드립니다.'
    }
]





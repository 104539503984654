import React, {useEffect, useState} from "react";
import {Row, Col, Button} from "reactstrap";
import {useLocation, useHistory} from 'react-router-dom';
import noticeList from '../../data/notice';

const Notice = () => {
    const location = useLocation();
    const history = useHistory();
    const [notice, setNotice] = useState({content : '' , title : ''});
    useEffect(() => {

        if (location.state.index) {
            const content = noticeList.filter(item => item.index === location.state.index);
            setNotice(content[0]);
        }
        return () => {

        };
    }, []);

    return (
        <div className='container'>
            <Row className='g-0'>
                <Col xs="12" className='text-center notice_view_header'>
                    <h1>공지사항</h1>
                    <h4>클라우드매직의 소식을 확인해 보세요.</h4>
                </Col>
                <Col xs={12} className='notice_view_wrapper'>
                    <div className='notice_view_title'>
                        <span className='notice_title'>{notice.title}</span>
                        <span className='notice_date'>{notice.date}</span>
                    </div>
                    <div className='notice_view_content'>
                        { notice.content.split('\n')
                            .map((line, index) => {
                                return (
                                    <span key={index}>{line}<br/></span>
                                )
                            })}
                    </div>
                </Col>
                <Col xs={12} className='notice_view_btn_wrapper'>
                    <Button onClick={() => history.goBack()} className='notice_view_btn'>목록</Button>
                </Col>
            </Row>
        </div>
    )
}

export default Notice;

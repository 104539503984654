import React, {useState} from 'react';
import CloudMagicContext from "./context/Context";
import {useDispatch} from "react-redux";


const CloudMagicProvider = ({children}) => {
    const dispatch = useDispatch();

    const [termModal, setTermModal] = useState(false);
    const [termSeq, setTermSeq] = useState(0);

    const openTermModal = (seq) => {
        setTermSeq(seq);
        setTermModal(true);
    }

    const value = {
        termModal,
        setTermModal,
        termSeq,
        setTermSeq,
        openTermModal
    }


    return (
        <CloudMagicContext.Provider value={value}>
            {children}
        </CloudMagicContext.Provider>
    );
};

export default CloudMagicProvider;

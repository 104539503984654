export default {
    product: [
        {
            index: '1_1',
            title: '제품 관련 문의를 하고싶어요',
            content: 'wpfl5040@naver.com 으로 문의 요청을 주시면 됩니다.'
        }, {
            index: '1_2',
            title: '라돈과 환경호르몬 으로부터 안전한가요?',
            content: '클라우드 매직 매트리스는 방사능검사 전문기관 해즈멧센터로부터 라돈 및 방사능 검사를 진행하였고 안전 결과를 받았습니다. 안심하고 사용 가능합니다.\n'
        }, {
            index: '1_3',
            title: '매트리스에 오염이 됐을때, 어떻게 해야 하나요?',
            content: '마른 수건으로 오염을 닦아내고, 바람이 잘 통하는 곳에서 건조하시길 바랍니다.\n'
        }, {
            index: '1_4',
            title: '매트리스가 부풀어 오르지 않아요',
            content: '메모리폼 소재는 운송 및 보관중에 매트리스가 약간 눌리거나 구김이 있을 수 있습니다. 이는 시간이 지남에 따라 완화 됩니다.'
        }, {
            index: '1_5',
            title: '메모리폼은 세탁이 가능한가요?',
            content: '메모리폼은 세탁이 불가능 합니다. 메모리폼 오염 방지를 위하여 소노시즌에 방수커버를 씌어주시거나 음료 등에 의하여 오염되지 않도록 주의하여 주십시오.\n'
        },
    ],
    change: [
        {
            index: '2_1',
            title: '교환 및 환불을 받고싶어요',
            content: '교환 및 반품은 카카오톡 채널 추가 후 상담 문의 요청 주시면 됩니다. 사전 문의 없이 임의로 제품을 발송하실 경우, 자동으로 반송되어 고객님께 배송비가 청구될 수 있으니 배송 전에 문의 주시길 바랍니다.\n'
        },
        {
            index: '2_2',
            title: '교환 및 환불이 불가능한 경우는 어떤 경우인가요?',
            content: '아래와 같은 사유로 인한 교환·반품 및 환불은 불가합니다.\n' +
                '- 사용 후에는 단순 변심으로 인한 교환 및 환불이 불가합니다.\n' +
                '- 세탁 후에는 교환, 반품 및 환불이 불가하니 제품의 결함 여부를 꼭 먼저 확인해주세요.\n' +
                '- 포장 과정 중 커버에 주름이 발생할 수 있으나 개봉 후 수일 내에 자연적으로 사라집니다.\n' +
                '- 칼이나 가위를 사용하여 포장 개봉 시 제품에 손상이 가지 않도록 주의해주세요. 사용자 과실로 인한 제품 손상은 책임지지 않습니다.\n' +
                '- 소재의 특성상 사용 시에 약간의 소음이 발생할 수 있으나 정상적인 제품입니다. (소재 간의 마찰음입니다.)\n' +
                '- 봉제선과 박음질은 미세하게 어긋남이 있을 수 있으며 제조 과정에서 실밥이 튀어나올 수 있습니다.\n' +
                '- 제품 사이즈는 제조 공정 및 사이즈 측정 방식에 따라 약간의 오차가 발생할 수 있습니다.\n' +
                '- 내장재 가장 자리에 소재의 끊김 현상이 있을 수 있습니다.\n' +
                '- 모니터 및 PC 사양에 따라 실제 제품의 색상과 다를 수 있습니다.\n' +
                '- 소비자 과실로 인한 꺼짐 및 변형이 발생한 경우 또는 새 제품으로 보기 어려울 정도로 제품이 더럽혀져 있는 경우에는 교환·반품 및 환불이 불가합니다.'
        }
    ],
    user: [
        {
            index: '3_1',
            title: '개인 정보를 수정하고 싶어요',
            content: '로그인 후 오른쪽 상단의 마이페이지 탭에서 수정이 가능합니다.'
        },
        {
            index: '3_2',
            title: '회원 탈퇴를 하고싶어요',
            content: '로그인 후 오른쪽 상단의 마이페이지 탭에서 탈퇴가 가능합니다.'
        }
    ]
}






export default [
    {
        id: 1,
        title: '이용약관 및 환불정책',
        description: "제 1 장 총 칙\n" +
            "\n" +
            "제1조 목적\n" +
            "이 약관은 \"데이터크레딧(이하 “회사”)”이 제공하는 “스터디 투게더”와 관련하여 회사와 이용자 간의 권리와 의무 및 필요한 사항을 정하는데 그 목적이 있습니다.\n" +
            "\n" +
            "\n" +
            "제 2 조 (용어의 정의)\n" +
            "1. 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n" +
            "1) “스터디 투게더(이하 “서비스”)”란 PC와 모바일에서 카메라를 통해 서로의 공부하는 영상을 공유할 수 있도록 서비스를 제공하는 미디어 플랫폼을 말합니다.\n" +
            "2) “이용자“란 본 약관에 동의하고 회사가 제공하는 서비스 이용 자격을 부여 받은 자를 의미합니다.\n" +
            "3) “방장”이란 스터디를 개설하고 운영하는 이용자를 의미합니다.\n" +
            "4) “결제 사업자“란 서비스를 설치하고 결제할 수 있도록 제공하는 전자상거래 제공자 일체(서비스내 결제를 제공하는 사업자 포함)를 말합니다. (예. 이니시스, 구글 플레이스토어, 앱스토어, 카카오페이, 네이버페이, Toss 등)\n" +
            "5) “포인트”이란 이용자가 서비스내 아이템을 구매할 수 있는 서비스내 재화를 의미합니다.\n" +
            "6) “스투샵”이란 포인트를 통해 이용자가 아이템을 구매할 수 있는 서비스의 기능을 의미합니다.\n" +
            "7) “충전”이란 이용자가 스투샵의 아이템을 구매하기 위해 회사에서 제공하는 결제수단으로 현금을 포인트로 전환하는 행위를 말합니다.\n" +
            "8) “환불”이란 이용자가 충전한 포인트를 현금으로 되돌려 받는 것을 말합니다.\n" +
            "9) “탈퇴”란 이용자가 서비스 이용 계약을 종료하는 의사표시를 말합니다.\n" +
            "\n" +
            "2. 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령에서 정하는 바를 따릅니다.\n" +
            "\n" +
            "\n" +
            "제 3 조 (약관의 효력과 변경)\n" +
            "1. 회사는 서비스의 초기화면에 이 약관을 명시합니다.\n" +
            "2. 이 약관은 이용자가 서비스를 이용함과 동시에 효력이 발생하며, 이 약관에 동의하지 않을 경우 이용자는 서비스 이용계약을 철회할 수 있습니다.\n" +
            "3. 회사는 이 약관을 관련 법령에 위배되지 않는 범위에서 변경할 수 있습니다.\n" +
            "4. 변경된 약관은 적용일 및 변경내용, 변경사유 등을 명시하여 적용일 15일 이전(다만, 이용자에게 불리한 약관의 개정의 경우에는 적용일 30일 이전)부터 적용일 전일까지 서비스에 고지한 후 적용일부터 효력이 발생됩니다.\n" +
            "5. 이용자는 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴할 수 있습니다.\n" +
            "6. 변경된 약관 공지 시 이용자가 회사에 명시적으로 \"거부\"의 의사표시를 하지 않거나 또는 이용자가 변경된 이용약관의 적용일 이후에도 서비스를 계속 사용하는 경우에는 변경된 약관에 동의한 것으로 간주합니다.\n" +
            "\n" +
            "\n" +
            "제 2 장 [이 용]\n" +
            "\n" +
            "\n" +
            "제 4 조 (이용 계약의 성립)\n" +
            "1. 서비스 이용계약은 서비스를 이용하려는 자가 본 약관에 동의하고 서비스를 이용함으로써 성립됩니다.\n" +
            "2. 청소년(만 19세 미만인 자)이나 피한정후견인, 피성년후견인이 서비스를 이용하려면 법정대리인의 동의를 얻어야 합니다. 또한 연령에 따라 일부 서비스에 대하여 이용이 제한될 수 있습니다.\n" +
            "\n" +
            "\n" +
            "제 5 조 (서비스 가입 및 계정)\n" +
            "1. 이용자는 회사가 제공하는 방식을 통해 서비스에 가입할 수 있습니다.\n" +
            "2. 회사는 서비스에 가입한 이용자에게 닉네임, 고유아이디가 포함된 계정을 제공합니다.\n" +
            "3. 회사는 계정 정보를 통해 미성년자 여부 확인 등의 이용자 관리업무를 수행합니다.\n" +
            "4. 이용자는 자신의 계정을 성실히 관리하여야 합니다. 이용자가 본인의 계정을 소홀히 관리하거나 제3자에게 이용을 승낙함으로써 발생하는 손해에 대하여는 이용자에게 책임이 있습니다.\n" +
            "\n" +
            "\n" +
            "제 7 조 (회사의 의무)\n" +
            "1. 회사는 관계 법령을 준수하고, 이 약관에서 정하는 권리의 행사와 의무의 이행을 신의에 따라 성실하게 수행합니다.\n" +
            "2. 회사는 계속적이고 안정적인 서비스의 제공을 위하여 최선의 노력을 다합니다.\n" +
            "3. 회사는 이용자들이 서비스를 편리하게 이용할 수 있도록 최선의 노력을 다합니다.\n" +
            "4. 회사는 이용자가 제기한 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 합리적인 기간 내에 신속하게 처리하기 위해 노력합니다.\n" +
            "\n" +
            "\n" +
            "제 8 조 (이용자의 의무)\n" +
            "1. 이용자는 서비스를 본래의 이용 목적 이외의 용도로 사용하거나 다음 각 호에 해당하는 행위를 해서는 안되며, 아래의 항을 위반하는 것으로 의심되는 이용자를 발견할 시 이메일, ‘신고하기’ 등을 통해 신고를 접수하여야 합니다.\n" +
            "1) 다른 이용자의 계정 혹은 타인의 결제 정보를 도용하거나 회사의 관리자를 사칭하는 행위\n" +
            "2) 서비스 이용 중 허위 사실을 기재하여 서비스 운영을 방해하는 행위\n" +
            "3) 회사가 제공한 계정을 타인에게 양도하는 행위\n" +
            "4) 회사의 서비스를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제, 유통하여 상업적으로 이용하는 행위\n" +
            "5) 사기성, 음란성, 사행성, 혐오성 메시지 혹은 음성 등이 담긴 콘텐츠를 방송하거나 게시하는 행위\n" +
            "6) 타인의 명예를 훼손하거나 사이버 불링, 폭력적인 행위를 하는 등 이용자의 정상적인 서비스 이용을 방해하는 행위\n" +
            "7) 저작권을 포함한 지적재산권, 초상권 등 회사 및 제 3자의 기타 권리를 침해하는 행위\n" +
            "8) 타인의 개인정보를 동의없이 수집, 저장, 유포, 게시하는 행위\n" +
            "9) 일반적이지 않은 방식으로 데이터를 조작하여 회사의 서비스 운영을 방해하는 행위\n" +
            "10) 컴퓨터 소프트웨어, 하드웨어, 전기 통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 전송, 게시, 유포, 사용하는 행위\n" +
            "11) 서버 해킹, 데이터 유출, 버그 등을 통해 서비스를 변경하거나 회사가 정하지 않은 방식으로 서비스를 이용하는 행위\n" +
            "12) 관계 법령, 서비스이용약관, 운영정책, 공지사항을 위배하는 행위\n" +
            "13) 기타 공공질서 및 미풍양속을 위반하거나 불법적, 부당한 방식의 행동을 하는 행위\n" +
            "2. 회사는 이용자가 제 1항에 정한 서비스 이용 제한 기준을 위반하여 회사의 서비스에 지장을 초래한 경우, 사전 통보 없이 기간을 정하여 서비스 이용을 제한할 수 있습니다.\n" +
            "3. 회사는 이용자의 이용 제한이 정당한 경우 이로 인하여 이용자가 입은 손해에 대해 배상하지 않습니다.\n" +
            "\n" +
            "\n" +
            "제 9 조 (게시물에 대한 저작권)\n" +
            "1. 이용자가 서비스 내에 게시한 콘텐츠의 저작권은 이용자 본인에게 있습니다.\n" +
            "2. 서비스 이용 시 다른 이용자 또는 제 3자의 저작권 등 기타 권리를 침해하는 행위는 금지됩니다. 서비스 이용 중 저작권과 관련하여 발생하는 분쟁에 대한 모든 책임은 이용자에게 있습니다.\n" +
            "3. 회사는 이용자가 게시한 콘텐츠를 국내외에서 제공하는 관련 서비스(홍보 등) 목적으로 사용할 수 있습니다.\n" +
            "4. 제 3항의 사용범위는 이용자가 서비스 내에 게시한 콘텐츠의 복제, 수정, 전시, 전송, 배포, 출판 및 2차 저작물과 편집 저작물 작성을 포함합니다.\n" +
            "5. 이용자가 게시한 콘텐츠를 상업적 혹은 제 3항에서 정하지 않은 방식으로 사용할 경우, 회사는 전화, 팩스, 전자 우편 등의 방식으로 이용자에게 동의를 얻을 수 있으며, 별도의 보상을 할 수 있습니다.\n" +
            "\n" +
            "\n" +
            "제 10 조 (서비스의 제공 시간 및 중지, 변경)\n" +
            "1. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간 서비스를 제공합니다.\n" +
            "2. 회사는 시스템 정기점검 등 서비스 운영상 필요한 경우, 일정기간 동안 서비스를 일시 중지시킬 수 있으며, 이러한 경우 회사는 그 내용 및 시간을 서비스 내에 공지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.\n" +
            "3. 회사는 새로운 서비스, 각종 버그 패치 등 서비스 운영 또는 기술상 필요한 경우에는 제공하고 있는 서비스의 전부 또는 일부를 수정, 추가, 폐지 등 변경할 수 있습니다. 변경될 서비스의 내용 및 일자 등에 대해서는 서비스 내에 공지함으로써 이용자에 대한 통지를 대체합니다.\n" +
            "4. 회사는 서비스의 기획이나 운영상 또는 회사의 긴박한 상황 등에 의해 서비스 전부를 중단할 필요가 있는 경우 서비스 내에 이를 공지하고 서비스의 제공을 중단할 수 있습니다. 단, 회사가 통제할 수 없는 사유로 인한 경우 공지없이 서비스가 중단될 수 있습니다.\n" +
            "\n" +
            "\n" +
            "제 11 조 (광고의 게재)\n" +
            "회사는 본 서비스를 유지하기 위하여 서비스 내에 광고를 게재할 수 있습니다.\n" +
            "서비스에 게재되는 제3자가 주체인 광고에 이용자가 참여하거나 거래함으로써 발생하는 손실과 손해에 대해서 회사는 어떠한 책임도 부담하지 않습니다.\n" +
            "\n" +
            "\n" +
            "제 12 조 (계약 해지)\n" +
            "이용자는 서비스 이용을 원하지 않는 경우, 회원탈퇴를 통하여 언제든지 이용 계약을 해지할 수 있으며 탈퇴 신청 이후에는 탈퇴 취소 및 탈퇴한 계정에 대해 복구할 수 없습니다.\n" +
            "이용자의 탈퇴 신청 7일 후 계정 탈퇴 및 개인 정보 삭제가 완료됩니다.\n" +
            "이용자는 계정 탈퇴 완료 이후에 서비스에 재가입이 가능합니다.\n" +
            "\n" +
            "\n" +
            "제 3 장 [결 제]\n" +
            "\n" +
            "\n" +
            "제 13 조 (포인트의 구매 및 유효기간)\n" +
            "이용자는 결제 사업자의 결제 정책에 따라 포인트를 구매할 수 있습니다. 이 경우, 결제 사업자의 결제 정책에 따라 결제 금액에 차이가 발생할 수 있습니다.\n" +
            "이용자가 구매한 포인트의 유효기간은 포인트를 구매한 날로부터 3년이며, 이 기간이 경과한 후에는 이용자는 해당 포인트에 대한 사용권을 상실합니다.\n" +
            "이용자는 포인트를 이용자 본인의 계정에서만 이용할 수 있으며, 제3자에게 양도, 대여, 매매할 수 없습니다.\n" +
            "\n" +
            "\n" +
            "제 14 조 (구매 취소)\n" +
            "이용자가 구매한 포인트은 구매일로부터 7일 이내에 별도의 수수료 없이 구매 취소가 가능합니다.\n" +
            "회사는 구매 취소 신청자로부터 제공받은 개인 정보 및 구매 내역을 확인하여 구매 취소를 진행합니다. 회사는 이용자의 정당한 구매 취소 사유를 확인하기 위해 추가 증빙자료를 요구할 수 있습니다. (예. 플랫폼사업자에 등록된 개인정보, 결제 사업자의 구매 또는 결제 내역, 고객의 의도와 상이하게 타인을 통해 구매되었다는 것을 확인할 수 있는 서류(가족관계 증명) 등)\n" +
            "다음 각 호의 경우 포인트의 구매 취소가 제한될 수 있습니다.\n" +
            "1) 구매 후 7일이 지난 포인트의 경우\n" +
            "2) 스투샵에서 아이템을 구매하기 위해 이미 포인트를 사용한 경우(이용자의 의도에 반하여 타인에 의해 사용되었을 경우도 포함)\n" +
            "3) 구매 당시 지급받은 포인트 수량에 차이가 있을 경우\n" +
            "4) 이벤트, 광고, 보상 등 무상으로 제공받은 포인트의 경우\n" +
            "5) 미성년자가 사술 등을 통해 성년자로 믿게 하여 구매, 사용한 경우\n" +
            "6) 이용자가 서비스를 탈퇴하여 구매 내역 등 정보 확인이 불가한 경우\n" +
            "미성년자가 법정대리인의 동의 없이 결제한 경우, 미성년자 또는 법정대리인은 포인트 구매를 취소할 수 있습니다. 이 경우 회사는 포인트 구매가 진행된 단말기 또는 신용카드 등 결제 수단 명의자를 기준으로 미성년자 여부를 판단합니다. 회사는 필요한 경우 구매 취소 신청자에게 미성년자 및 법정대리인을 증명할 수 있는 서류를 요청할 수 있습니다.\n" +
            "\n" +
            "\n" +
            "제 15 조 (환불)\n" +
            "이용자가 제14조에 따라 구매 취소를 한 경우 회사는 지체 없이 해당 포인트를 회수하고 구매 취소를 접수한 날로부터 7 영업일 이내에 환불을 진행합니다.\n" +
            "회사가 이용자에게 환불을 지연한 때에는 그 지연기간에 대하여 「전자상거래법」에서 정하는 지연이자를 지급합니다. 단, 이용자의 귀책사유로 인한 경우 또는 결제사의 정책에 따라 환불이 지연되는 경우 지연이자를 지급하지 않습니다.\n" +
            "회사의 귀책사유로 인하여 구매한 포인트를 이용하지 못하는 경우, 동일한 수량의 제공하거나 해당 구매 금액을 구매일에 관계없이 전액 환불합니다.\n" +
            "환불은 회사 및 각 앱스토어 사업자의 환불 정책에 따라 진행됩니다.\n" +
            "회사는 서비스 종료 시 이용자의 구매 취소 신청 여부와 관계없이 이용자가 보유한 포인트를 전액 환불합니다. 단, 사용기간이 경과한 포인트는 환불하지 않습니다.\n" +
            "\n" +
            "\n" +
            "제 4 장 기타\n" +
            "\n" +
            "\n" +
            "제 17 조 (손해배상)\n" +
            "이용자가 이 약관에서 정한 사항을 위반함으로써 회사 또는 제 3자에게 손해를 입힌 경우 이용자는 회사 또는 제 3자에게 그 손해를 배상하여야 합니다.\n" +
            "\n" +
            "\n" +
            "제 18 조 (책임제한)\n" +
            "회사는 서비스의 이용장애 혹은 서비스가 변경되거나 중지된 원인이 회사의 고의 또는 중과실로 인한 경우를 제외하고는 서비스의 이용장애, 변경 및 중지로 인하여 이용자에게 발생하는 손해에 대해서는 책임이 면제됩니다.\n" +
            "회사는 이용자가 서비스를 이용하여 기대하는 점수, 순위 등을 얻지 못한 것에 대해서는 책임이 면제됩니다.\n" +
            "회사는 이용자가 본인의 개인정보 등(계정 포함)을 변경하여 얻는 불이익 및 정보 상실에 대해서는 책임이 면제됩니다.\n" +
            "회사는 이용자 상호간 또는 이용자와 제3자 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.\n" +
            "\n" +
            "\n" +
            "제 19 조 (약관 외 준칙)\n" +
            "이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「전자상거래법」, 「약관 규제법」,「정보통신망법」, 「콘텐츠산업진흥법」 등 관계 법령에 따릅니다.\n" +
            "회사는 본 약관 외에 운영정책, 개인정보처리방침 등 개별 약관을 둘 수 있습니다.\n" +
            "\n" +
            "\n" +
            "제 20 조 (재판권 및 준거법)\n" +
            "회사와 이용자간에 제기된 소송에는 대한민국 법을 적용합니다.\n" +
            "서비스 이용과 관련하여 회사와 이용자간에 발생한 분쟁에 대해서는 민사소송법의 주소지를 관할하는 법원을 합의관할로 합니다.\n" +
            "이 약관은 2020년 04월 01일부터 시행합니다.",
        need: true
    },
    {
        id: 2,
        title: '개인정보 취급방침',
        description: "<데이터크레딧>('https://studytogether.kr/'이하 '스터디 투게더')은(는) 「개인정보 보호법」 제30조에 따라 정부주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.\n" +
            "\n" +
            "○ 이 개인정보처리방침은 2021년 4월 1부터 적용됩니다.\n" +
            "\n" +
            "\n" +
            "제1조(개인정보의 처리 목적)\n" +
            "\n" +
            "<데이터크레딧>('https://studytogether.kr/'이하 '스터디 투게더')은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 겨우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.\n" +
            "\n" +
            "1. 홈페이지 회원가입 및 관리\n" +
            "\n" +
            "회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.\n" +
            "\n" +
            "\n" +
            "2. 민원사무 처리\n" +
            "\n" +
            "민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.\n" +
            "\n" +
            "\n" +
            "3. 재화 또는 서비스 제공\n" +
            "\n" +
            "물품배송, 서비스 제공, 계약서·청구서 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심을 목적으로 개인정보를 처리합니다.\n" +
            "\n" +
            "\n" +
            "4. 마케팅 및 광고에의 활용\n" +
            "\n" +
            "신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.\n" +
            "\n" +
            "\n" +
            "5. 개인영상정보\n" +
            "\n" +
            "범죄의 예방 및 수사 등을 목적으로 개인정보를 처리합니다.\n" +
            "\n" +
            "\n" +
            "\n" +
            "\n" +
            "제2조(개인정보의 처리 및 보유 기간)\n" +
            "\n" +
            "① <데이터크레딧>은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.\n" +
            "\n" +
            "② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.\n" +
            "\n" +
            "1.<홈페이지 회원가입 및 관리>\n" +
            "<홈페이지 회원가입 및 관리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<서비스 탈퇴 시점까지>까지 위 이용목적을 위하여 보유.이용됩니다.\n" +
            "보유근거 : 홈페이지 회원가입 및 관리를 위함\n" +
            "관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n" +
            "2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n" +
            "3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n" +
            "4) 계약 또는 청약철회 등에 관한 기록 : 5년\n" +
            "5) 표시/광고에 관한 기록 : 6개월\n" +
            "예외사유 :\n" +
            "2.<민원사무 처리>\n" +
            "<민원사무 처리>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<영구>까지 위 이용목적을 위하여 보유.이용됩니다.\n" +
            "보유근거 : 고객센터 및 기타 민원사무처리를 위함\n" +
            "관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n" +
            "2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n" +
            "3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n" +
            "4) 계약 또는 청약철회 등에 관한 기록 : 5년\n" +
            "5) 표시/광고에 관한 기록 : 6개월\n" +
            "1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n" +
            "2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n" +
            "예외사유 :\n" +
            "3.<제화 또는 서비스 제공>\n" +
            "<제화 또는 서비스 제공>와 관련한 개인정보는 수집.이용에 관한 동의일로부터<준영구>까지 위 이용목적을 위하여 보유.이용됩니다.\n" +
            "보유근거 : 제화 또는 서비스 제공을 위함\n" +
            "관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n" +
            "2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n" +
            "3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n" +
            "4) 계약 또는 청약철회 등에 관한 기록 : 5년\n" +
            "5) 표시/광고에 관한 기록 : 6개월\n" +
            "1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n" +
            "2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년\n" +
            "1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년\n" +
            "2) 대금결제 및 재화 등의 공급에 관한 기록 : 5년\n" +
            "예외사유 :\n" +
            "\n" +
            "\n" +
            "제3조(개인정보의 제3자 제공)\n" +
            "\n" +
            "① <데이터크레딧>은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.\n" +
            "\n" +
            "② <데이터크레딧>은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.\n" +
            "\n" +
            "1. <채널톡>\n" +
            "개인정보를 제공받는 자 : 채널톡\n" +
            "제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 로그인ID, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록\n" +
            "제공받는 자의 보유.이용기간: 준영구\n" +
            "\n" +
            "\n" +
            "제4조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)\n" +
            "\n" +
            "\n" +
            "\n" +
            "① 정보주체는 데이터크레딧에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.\n" +
            "\n" +
            "② 제1항에 따른 권리 행사는데이터크레딧에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 데이터크레딧은(는) 이에 대해 지체 없이 조치하겠습니다.\n" +
            "\n" +
            "③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.\n" +
            "\n" +
            "④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.\n" +
            "\n" +
            "⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.\n" +
            "\n" +
            "⑥ 데이터크레딧은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.\n" +
            "\n" +
            "\n" +
            "\n" +
            "제5조(처리하는 개인정보의 항목 작성)\n" +
            "\n" +
            "① <데이터크레딧>은(는) 다음의 개인정보 항목을 처리하고 있습니다.\n" +
            "\n" +
            "1< 홈페이지 회원가입 및 관리 >\n" +
            "필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록\n" +
            "선택항목 : 성별, 생년월일\n" +
            "2< 민원사무 처리 >\n" +
            "필수항목 : 접속 로그, 쿠키, 접속 IP 정보\n" +
            "선택항목 : 이메일, 휴대전화번호, 로그인ID, 이름, 서비스 이용 기록, 결제기록\n" +
            "\n" +
            "\n" +
            "제6조(개인정보의 파기)\n" +
            "\n" +
            "\n" +
            "① <데이터크레딧> 은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.\n" +
            "\n" +
            "② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.\n" +
            "1. 법령 근거 :\n" +
            "2. 보존하는 개인정보 항목 : 계좌정보, 거래날짜\n" +
            "\n" +
            "③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.\n" +
            "1. 파기절차\n" +
            "<데이터크레딧> 은(는) 파기 사유가 발생한 개인정보를 선정하고, <데이터크레딧> 의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.\n" +
            "\n" +
            "2. 파기방법\n" +
            "\n" +
            "전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.\n" +
            "\n" +
            "종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다\n" +
            "\n" +
            "\n" +
            "제7조(개인정보의 안전성 확보 조치)\n" +
            "\n" +
            "<데이터크레딧>은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.\n" +
            "\n" +
            "1. 정기적인 자체 감사 실시\n" +
            "개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.\n" +
            "\n" +
            "2. 개인정보 취급 직원의 최소화 및 교육\n" +
            "개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.\n" +
            "\n" +
            "3. 해킹 등에 대비한 기술적 대책\n" +
            "<데이터크레딧>('스터디 투게더')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.\n" +
            "\n" +
            "4. 개인정보의 암호화\n" +
            "이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.\n" +
            "\n" +
            "5. 접속기록의 보관 및 위변조 방지\n" +
            "개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다.\n" +
            "\n" +
            "6. 개인정보에 대한 접근 제한\n" +
            "개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.\n" +
            "\n" +
            "7. 문서보안을 위한 잠금장치 사용\n" +
            "개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다.\n" +
            "\n" +
            "8. 비인가자에 대한 출입 통제\n" +
            "개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.\n" +
            "\n" +
            "\n" +
            "\n" +
            "\n" +
            "제8조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)\n" +
            "\n" +
            "\n" +
            "\n" +
            "① 데이터크레딧 은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.\n" +
            "\n" +
            "제9조 (개인정보 보호책임자)\n" +
            "\n" +
            "① 데이터크레딧 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.\n" +
            "\n" +
            "▶ 개인정보 보호책임자\n" +
            "성명 :한찬희\n" +
            "직책 :대표이사\n" +
            "직급 :대표이사\n" +
            "연락처 :070-8885-0090, support@studytogether.kr, 0504-092-7907\n" +
            "※ 개인정보 보호 담당부서로 연결됩니다.\n" +
            "\n" +
            "▶ 개인정보 보호 담당부서\n" +
            "부서명 :고객센터\n" +
            "담당자 :한찬희\n" +
            "연락처 :070-8885-0090, support@studytogether.kr, 0504-092-7907\n" +
            "② 정보주체께서는 데이터크레딧 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 데이터크레딧 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.\n" +
            "\n" +
            "제10조(개인정보 열람청구)\n" +
            "정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.\n" +
            "<데이터크레딧>은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.\n" +
            "\n" +
            "▶ 개인정보 열람청구 접수·처리 부서\n" +
            "부서명 : 고객센터\n" +
            "담당자 : 한찬희\n" +
            "연락처 : 070-8885-0090, support@studytogether.kr, 0504-092-7907\n" +
            "\n" +
            "\n" +
            "제11조(권익침해 구제방법)\n" +
            "\n" +
            "\n" +
            "\n" +
            "정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.\n" +
            "\n" +
            "1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)\n" +
            "2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)\n" +
            "3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)\n" +
            "4. 경찰청 : (국번없이) 182 (cyberbureau.police.go.kr)\n" +
            "\n" +
            "「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.\n" +
            "\n" +
            "※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.\n" +
            "\n" +
            "제12조(개인정보 처리방침 변경)\n" +
            "\n" +
            "\n" +
            "① 이 개인정보처리방침은 2021년 4월 1부터 적용됩니다.\n" +
            "\n" +
            "② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.",
        need: true
    }
    // },
    // {
    //     id: 5,
    //     title: '휴대전화 본인인증 이용 동의',
    //     description: "And they'd probably do a lot of damage to an...",
    //     need: true
    // }
]

import React from "react";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import {Link} from "react-router-dom";
import LandingSlick from "./LandingSlick";
import LandingNotice from "./LandingNotice";
import landing_section_img from '../../asset/img/landing_section_img.png';
import LandingCarousel from "./LandingCarousel";
import landing_mat_detail from '../../asset/img/landing/landing_mat_detail.png';
import LandingBestItem from "./LandingBestItem";
import landing_mat_detail_brand from '../../asset/img/landing/landing_mat_detail_brand.png';
import landing_mat_detail_guard from '../../asset/img/landing/landing_mat_detail_guard.png';
import landing_mat_detail_mem from '../../asset/img/landing/landing_mat_detail_mem.png';
import landing_mat_detail_sleep from '../../asset/img/landing/landing_mat_detail_sleep.png';
import {isMobile} from 'react-device-detect';
import {useHistory} from "react-router-dom";

const Landing = () => {
    const history = useHistory();
    const animatedItem2 = useScrollFadeIn('up', 1, 0);
    const animatedItem4 = useScrollFadeIn(isMobile ? 'up' : 'right', 1, 0);
    const animatedItem5 = useScrollFadeIn(isMobile ? 'up' : 'left', 1, 0);
    return (
        <div className='landing_container'>
            <LandingCarousel/>
            <div className='landing_best_wrapper'>
                <div className='landing_best_item container'>
                    <div className='landing_best_item_content'>
                        <span>CLOUD MAGIC MATTRESS</span>
                        <h2>나에게 맞는 매트리스 찾기</h2>
                        <div className='small_txt'>
                            <span>자고 일어났는데 피곤하다면 매트리스가 맞지 않는 것입니다.</span><br/>
                            <span>클라우드 매직 매트리스로 수면 건강을 챙겨보세요.</span>
                        </div>
                        <div className='stick_container'>
                            <div id='back_stick'/>
                        </div>
                        <div className='product_txt'>
                            <h4>Products</h4>
                            <div className='mx-auto' style={{width: 'fit-content'}}>
                                <hr/>
                            </div>
                            <div className='mt-3'>
                                <span>매트리스</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LandingBestItem/>
            <section className='landing_mat_detail_wrapper'>
                <div className='mat_detail_top'>
                    <small>features</small>
                    <h2>클라우드 매직만의 특징</h2>
                    <span>편안한 하루에 마무리</span>
                    <span>클라우드 매직과 함께 해보세요!</span>
                </div>
                <div className='mat_detail_bottom'>
                    <div {...animatedItem5} className='left_detail_content'>
                        <div className='detail_content_item'>
                            <div className='txt_content'>
                                <h4>편안한 수면</h4>
                                <p>
                                    딱딱한 매트리스부터 푹신한 매트리스까지
                                    나에게 딱 맞는 매트리스를 선택해
                                    편안한 수면을 느껴보세요.
                                </p>
                            </div>
                            <div className='icon_content'>
                                <img src={landing_mat_detail_sleep}/>
                            </div>
                        </div>
                        <div className='detail_content_item'>
                            <div className='txt_content'>
                                <h4>안전한 매트리스</h4>
                                <p>
                                    공인된 연구 기관을 통해 라돈과 방사선으로부터
                                    안전함을 검증받은 친환경 매트리스로서
                                    안전한 잠자리를 약속드립니다.
                                </p>
                            </div>
                            <div className='icon_content'>
                                <img src={landing_mat_detail_guard}/>
                            </div>
                        </div>
                    </div>
                    <div {...animatedItem2} className='center_detail_content'><img src={landing_mat_detail}/></div>
                    <div {...animatedItem4} className='right_detail_content'>
                        <div className='detail_content_item'>
                            <div className='icon_content'>
                                <img src={landing_mat_detail_mem}/>
                            </div>
                            <div className='txt_content'>
                                <h4>특별한 외장재</h4>
                                <p>
                                    클라우드 매직 매트리스의 외장재는
                                    매우 뛰어난 전도성을 가지고 있으며
                                    몸에서 정전기를 제거하고 전자파를 차단함으로서
                                    편안한 숙면을 취할 수 있도록 도와줍니다.
                                </p>
                            </div>

                        </div>
                        <div className='detail_content_item go_story_wrapper'>
                            <div className='icon_content'>
                                <img id='book_brand' src={landing_mat_detail_brand}/>
                            </div>
                            <div className='txt_content'>
                                <h4><span><Link to={'/list'}>Product</Link></span> or <span><Link to={'/showRoom'}>Store</Link></span></h4>
                                <button onClick={()=>history.push('/introduce')}>CLOUD STORY</button>
                            </div>

                        </div>
                    </div>

                </div>
            </section>
            <LandingSlick/>
            <LandingNotice/>
            <section className='landing_section_img' style={{backgroundImage: `url(${landing_section_img})`}}>
                <Link to={'/showRoom'}>
                    <div className='landgin_section_img_wrapper'>
                        {/*<img src={section_img_2}/>*/}
                        <div className='img_txt'>
                            <span>Cloud Magic</span>
                            <span>클라우드 매직 매트리스를 직접 경험해보세요!</span>
                            <span>매장 보러 가기</span>
                        </div>
                    </div>
                </Link>
            </section>
        </div>
    )
}

export default Landing;

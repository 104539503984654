export default [
    {
        title: '순천점',
        address: '전라남도 순천시 해룡면 여순로 1513',
        lat: '34.91457098209136',
        lng: '127.53926360870834',
        time: '10:00 am - 7:30 pm',
        phone: '061-723-7747'
    },
    {
        title: '포천점',
        address: '경기 포천시 호국로 1153-9',
        lat: '37.866185000194164',
        lng: '127.17432863628379',
        time: '09:30 am - 6:00 pm',
        phone: '031-542-9475'
    }
]

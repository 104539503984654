import detail_big_1_1 from '../asset/img/mattress_img/1/detail/detail_big_1_1.png';
import detail_big_1_2 from '../asset/img/mattress_img/1/detail/detail_big_1_2.png';
import detail_big_1_3 from '../asset/img/mattress_img/1/detail/detail_big_1_3.png';
import detail_big_1_4 from '../asset/img/mattress_img/1/detail/detail_big_1_4.png';
import detail_big_1_5 from '../asset/img/mattress_img/1/detail/detail_big_1_5.png';
import detail_small_1_1 from '../asset/img/mattress_img/1/detail/detail_small_1_1.png';
import detail_small_1_2 from '../asset/img/mattress_img/1/detail/detail_small_1_2.png';
import detail_small_1_3 from '../asset/img/mattress_img/1/detail/detail_small_1_3.png';
import detail_small_1_4 from '../asset/img/mattress_img/1/detail/detail_small_1_4.png';
import detail_small_1_5 from '../asset/img/mattress_img/1/detail/detail_small_1_5.png';

import detail_big_2_1 from '../asset/img/mattress_img/2/detail/detail_big_2_1.png';
import detail_big_2_2 from '../asset/img/mattress_img/2/detail/detail_big_2_2.png';
import detail_big_2_3 from '../asset/img/mattress_img/2/detail/detail_big_2_3.png';
import detail_big_2_4 from '../asset/img/mattress_img/2/detail/detail_big_2_4.png';
import detail_big_2_5 from '../asset/img/mattress_img/2/detail/detail_big_2_5.png';
import detail_small_2_1 from '../asset/img/mattress_img/2/detail/detail_small_2_1.png';
import detail_small_2_2 from '../asset/img/mattress_img/2/detail/detail_small_2_2.png';
import detail_small_2_3 from '../asset/img/mattress_img/2/detail/detail_small_2_3.png';
import detail_small_2_4 from '../asset/img/mattress_img/2/detail/detail_small_2_4.png';
import detail_small_2_5 from '../asset/img/mattress_img/2/detail/detail_small_2_5.png';

import detail_big_3_1 from '../asset/img/mattress_img/3/detail/detail_big_3_1.png';
import detail_big_3_2 from '../asset/img/mattress_img/3/detail/detail_big_3_2.png';
import detail_big_3_3 from '../asset/img/mattress_img/3/detail/detail_big_3_3.png';
import detail_big_3_4 from '../asset/img/mattress_img/3/detail/detail_big_3_4.png';
import detail_big_3_5 from '../asset/img/mattress_img/3/detail/detail_big_3_5.png';
import detail_small_3_1 from '../asset/img/mattress_img/3/detail/detail_small_3_1.png';
import detail_small_3_2 from '../asset/img/mattress_img/3/detail/detail_small_3_2.png';
import detail_small_3_3 from '../asset/img/mattress_img/3/detail/detail_small_3_3.png';
import detail_small_3_4 from '../asset/img/mattress_img/3/detail/detail_small_3_4.png';
import detail_small_3_5 from '../asset/img/mattress_img/3/detail/detail_small_3_5.png';

import detail_big_4_1 from '../asset/img/mattress_img/4/detail/detail_big_4_1.png';
import detail_big_4_2 from '../asset/img/mattress_img/4/detail/detail_big_4_2.png';
import detail_big_4_3 from '../asset/img/mattress_img/4/detail/detail_big_4_3.png';
import detail_big_4_4 from '../asset/img/mattress_img/4/detail/detail_big_4_4.png';
import detail_big_4_5 from '../asset/img/mattress_img/4/detail/detail_big_4_5.png';
import detail_small_4_1 from '../asset/img/mattress_img/4/detail/detail_small_4_1.png';
import detail_small_4_2 from '../asset/img/mattress_img/4/detail/detail_small_4_2.png';
import detail_small_4_3 from '../asset/img/mattress_img/4/detail/detail_small_4_3.png';
import detail_small_4_4 from '../asset/img/mattress_img/4/detail/detail_small_4_4.png';
import detail_small_4_5 from '../asset/img/mattress_img/4/detail/detail_small_4_5.png';

import detail_big_5_1 from '../asset/img/mattress_img/5/detail/detail_big_5_1.png';
import detail_big_5_2 from '../asset/img/mattress_img/5/detail/detail_big_5_2.png';
import detail_big_5_3 from '../asset/img/mattress_img/5/detail/detail_big_5_3.png';
import detail_big_5_4 from '../asset/img/mattress_img/5/detail/detail_big_5_4.png';
import detail_big_5_5 from '../asset/img/mattress_img/5/detail/detail_big_5_5.png';
import detail_small_5_1 from '../asset/img/mattress_img/5/detail/detail_small_5_1.png';
import detail_small_5_2 from '../asset/img/mattress_img/5/detail/detail_small_5_2.png';
import detail_small_5_3 from '../asset/img/mattress_img/5/detail/detail_small_5_3.png';
import detail_small_5_4 from '../asset/img/mattress_img/5/detail/detail_small_5_4.png';
import detail_small_5_5 from '../asset/img/mattress_img/5/detail/detail_small_5_5.png';

import detail_big_6_1 from '../asset/img/mattress_img/6/detail/detail_big_6_1.png';
import detail_big_6_2 from '../asset/img/mattress_img/6/detail/detail_big_6_2.png';
import detail_big_6_3 from '../asset/img/mattress_img/6/detail/detail_big_6_3.png';
import detail_big_6_4 from '../asset/img/mattress_img/6/detail/detail_big_6_4.png';
import detail_big_6_5 from '../asset/img/mattress_img/6/detail/detail_big_6_5.png';
import detail_small_6_1 from '../asset/img/mattress_img/6/detail/detail_small_6_1.png';
import detail_small_6_2 from '../asset/img/mattress_img/6/detail/detail_small_6_2.png';
import detail_small_6_3 from '../asset/img/mattress_img/6/detail/detail_small_6_3.png';
import detail_small_6_4 from '../asset/img/mattress_img/6/detail/detail_small_6_4.png';
import detail_small_6_5 from '../asset/img/mattress_img/6/detail/detail_small_6_5.png';

import detail_big_7_1 from '../asset/img/mattress_img/7/detail/detail_big_7_1.png';
import detail_big_7_2 from '../asset/img/mattress_img/7/detail/detail_big_7_2.png';
import detail_big_7_3 from '../asset/img/mattress_img/7/detail/detail_big_7_3.png';
import detail_big_7_4 from '../asset/img/mattress_img/7/detail/detail_big_7_4.png';
import detail_big_7_5 from '../asset/img/mattress_img/7/detail/detail_big_7_5.png';
import detail_small_7_1 from '../asset/img/mattress_img/7/detail/detail_small_7_1.png';
import detail_small_7_2 from '../asset/img/mattress_img/7/detail/detail_small_7_2.png';
import detail_small_7_3 from '../asset/img/mattress_img/7/detail/detail_small_7_3.png';
import detail_small_7_4 from '../asset/img/mattress_img/7/detail/detail_small_7_4.png';
import detail_small_7_5 from '../asset/img/mattress_img/7/detail/detail_small_7_5.png';

import detail_big_8_1 from '../asset/img/mattress_img/8/detail/detail_big_8_1.png';
import detail_big_8_2 from '../asset/img/mattress_img/8/detail/detail_big_8_2.png';
import detail_big_8_3 from '../asset/img/mattress_img/8/detail/detail_big_8_3.png';
import detail_big_8_4 from '../asset/img/mattress_img/8/detail/detail_big_8_4.png';
import detail_big_8_5 from '../asset/img/mattress_img/8/detail/detail_big_8_5.png';

import list_main_1_1 from '../asset/img/mattress_img/1/list_main_1_1.png';
import list_main_1_2 from '../asset/img/mattress_img/1/list_main_1_2.png';

import list_main_2_1 from '../asset/img/mattress_img/2/list_main_2_1.png';
import list_main_2_2 from '../asset/img/mattress_img/2/list_main_2_2.png';

import list_main_3_1 from '../asset/img/mattress_img/3/list_main_3_1.png';
import list_main_3_2 from '../asset/img/mattress_img/3/list_main_3_2.png';

import list_main_4_1 from '../asset/img/mattress_img/4/list_main_4_1.png';
import list_main_4_2 from '../asset/img/mattress_img/4/list_main_4_2.png';

import list_main_5_1 from '../asset/img/mattress_img/5/list_main_5_1.png';
import list_main_5_2 from '../asset/img/mattress_img/5/list_main_5_2.png';

import list_main_6_1 from '../asset/img/mattress_img/6/list_main_6_1.png';
import list_main_6_2 from '../asset/img/mattress_img/6/list_main_6_2.png';

import list_main_7_1 from '../asset/img/mattress_img/7/list_main_7_1.png';
import list_main_7_2 from '../asset/img/mattress_img/7/list_main_7_2.png';

import list_main_8_1 from '../asset/img/mattress_img/8/list_main_8_1.png';
import list_main_8_2 from '../asset/img/mattress_img/8/list_main_8_2.png';

import nav_detail_img_1 from '../asset/img/mattress_img/1/nav_detail_image_1.png';
import nav_detail_img_2 from '../asset/img/mattress_img/2/nav_detail_image_2.png';
import nav_detail_img_3 from '../asset/img/mattress_img/3/nav_detail_image_3.png';
import nav_detail_img_4 from '../asset/img/mattress_img/4/nav_detail_image_4.png';
import nav_detail_img_5 from '../asset/img/mattress_img/5/nav_detail_image_5.png';
import nav_detail_img_6 from '../asset/img/mattress_img/6/nav_detail_image_6.png';
import nav_detail_img_7 from '../asset/img/mattress_img/7/nav_detail_image_7.png';

import detail_mobile_1_1 from '../asset/img/mattress_img/1/detail/detail_mobile_1_1.png';
import detail_mobile_1_2 from '../asset/img/mattress_img/1/detail/detail_mobile_1_2.png';
import detail_mobile_1_3 from '../asset/img/mattress_img/1/detail/detail_mobile_1_3.png';
import detail_mobile_1_4 from '../asset/img/mattress_img/1/detail/detail_mobile_1_4.png';
import detail_mobile_1_5 from '../asset/img/mattress_img/1/detail/detail_mobile_1_5.png';

import detail_mobile_2_1 from '../asset/img/mattress_img/2/detail/detail_mobile_2_1.png';
import detail_mobile_2_2 from '../asset/img/mattress_img/2/detail/detail_mobile_2_2.png';
import detail_mobile_2_3 from '../asset/img/mattress_img/2/detail/detail_mobile_2_3.png';
import detail_mobile_2_4 from '../asset/img/mattress_img/2/detail/detail_mobile_2_4.png';
import detail_mobile_2_5 from '../asset/img/mattress_img/2/detail/detail_mobile_2_5.png';

import detail_mobile_3_1 from '../asset/img/mattress_img/3/detail/detail_mobile_3_1.png';
import detail_mobile_3_2 from '../asset/img/mattress_img/3/detail/detail_mobile_3_2.png';
import detail_mobile_3_3 from '../asset/img/mattress_img/3/detail/detail_mobile_3_3.png';
import detail_mobile_3_4 from '../asset/img/mattress_img/3/detail/detail_mobile_3_4.png';
import detail_mobile_3_5 from '../asset/img/mattress_img/3/detail/detail_mobile_3_5.png';

import detail_mobile_4_1 from '../asset/img/mattress_img/4/detail/detail_mobile_4_1.png';
import detail_mobile_4_2 from '../asset/img/mattress_img/4/detail/detail_mobile_4_2.png';
import detail_mobile_4_3 from '../asset/img/mattress_img/4/detail/detail_mobile_4_3.png';
import detail_mobile_4_4 from '../asset/img/mattress_img/4/detail/detail_mobile_4_4.png';
import detail_mobile_4_5 from '../asset/img/mattress_img/4/detail/detail_mobile_4_5.png';

import detail_mobile_5_1 from '../asset/img/mattress_img/5/detail/detail_mobile_5_1.png';
import detail_mobile_5_2 from '../asset/img/mattress_img/5/detail/detail_mobile_5_2.png';
import detail_mobile_5_3 from '../asset/img/mattress_img/5/detail/detail_mobile_5_3.png';
import detail_mobile_5_4 from '../asset/img/mattress_img/5/detail/detail_mobile_5_4.png';
import detail_mobile_5_5 from '../asset/img/mattress_img/5/detail/detail_mobile_5_5.png';

import detail_mobile_6_1 from '../asset/img/mattress_img/6/detail/detail_mobile_6_1.png';
import detail_mobile_6_2 from '../asset/img/mattress_img/6/detail/detail_mobile_6_2.png';
import detail_mobile_6_3 from '../asset/img/mattress_img/6/detail/detail_mobile_6_3.png';
import detail_mobile_6_4 from '../asset/img/mattress_img/6/detail/detail_mobile_6_4.png';
import detail_mobile_6_5 from '../asset/img/mattress_img/6/detail/detail_mobile_6_5.png';

import detail_mobile_7_1 from '../asset/img/mattress_img/7/detail/detail_mobile_7_1.png';
import detail_mobile_7_2 from '../asset/img/mattress_img/7/detail/detail_mobile_7_2.png';
import detail_mobile_7_3 from '../asset/img/mattress_img/7/detail/detail_mobile_7_3.png';
import detail_mobile_7_4 from '../asset/img/mattress_img/7/detail/detail_mobile_7_4.png';
import detail_mobile_7_5 from '../asset/img/mattress_img/7/detail/detail_mobile_7_5.png';

import hard_type_S from '../asset/img/hardType/hard_type_S.png';
import hard_type_SM from '../asset/img/hardType/hard_type_SM.png';
import hard_type_M from '../asset/img/hardType/hard_type_M.png';
import hard_type_MH from '../asset/img/hardType/hard_type_MH.png';
import hard_type_H from '../asset/img/hardType/hard_type_H.png';

import detail_main_img_1 from '../asset/img/mattress_img/1/detail_main_img_1.png';
import detail_main_img_2 from '../asset/img/mattress_img/2/detail_main_img_2.png';
import detail_main_img_3 from '../asset/img/mattress_img/3/detail_main_img_3.png';
import detail_main_img_4 from '../asset/img/mattress_img/4/detail_main_img_4.png';
import detail_main_img_5 from '../asset/img/mattress_img/5/detail_main_img_5.png';
import detail_main_img_6 from '../asset/img/mattress_img/6/detail_main_img_6.png';
import detail_main_img_7 from '../asset/img/mattress_img/7/detail_main_img_7.png';
import detail_main_img_2_1 from '../asset/img/mattress_img/2/detail_main_img_2_1.png';
import detail_main_img_2_2 from '../asset/img/mattress_img/2/detail_main_img_2_2.png';
import detail_main_img_7_1 from '../asset/img/mattress_img/7/detail_main_img_7_2.png';
import detail_main_img_7_2 from '../asset/img/mattress_img/7/detail_main_img_7_3.png';
import detail_main_img_8 from '../asset/img/mattress_img/8/detail_main_img_8.png';


import sleep_maker_movie_main from '../asset/img/mattress_img/movie/sleepmaker.mp4';
import sleep_maker_movie_gif_1 from '../asset/img/mattress_img/movie/sleep_maker_movie.gif';
import sleep_maker_movie_gif_2 from '../asset/img/mattress_img/movie/not_sleep_maker_movie.gif';

export default [
    {
        'no': 1,
        'title': '앨리스 포켓 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_1,
        'detail_img_big': [detail_big_1_1, detail_big_1_2, detail_big_1_3, detail_big_1_4, detail_big_1_5],
        'detail_img_small': [detail_small_1_1, detail_small_1_2, detail_small_1_3, detail_small_1_4, detail_small_1_5],
        'detail_mobile_img' : [detail_mobile_1_1,detail_mobile_1_2,detail_mobile_1_3,detail_mobile_1_4,detail_mobile_1_5],
        'nav_img': detail_small_1_1,
        'nav_detail_img': nav_detail_img_1,
        'list_img': [list_main_1_1, list_main_1_2],
        'hard_type_img' : hard_type_MH,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814443218',
        'firstPrice': '2,250,000',
        'price': {
            K: '2,050,000',
            Q: '1,750,000',
            SS: '1,450,000'
        }
    },
    {
        'no': 2,
        'title': '알란 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : true,
        'detail_main_img' : [detail_main_img_2,detail_main_img_2_1,detail_main_img_2_2,sleep_maker_movie_main,sleep_maker_movie_gif_1,sleep_maker_movie_gif_2],
        'detail_img_big': [detail_big_2_1, detail_big_2_2, detail_big_2_3, detail_big_2_4, detail_big_2_5],
        'detail_img_small': [detail_small_2_1, detail_small_2_2, detail_small_2_3, detail_small_2_4, detail_small_2_5],
        'detail_mobile_img' : [detail_mobile_2_1,detail_mobile_2_2,detail_mobile_2_3,detail_mobile_2_4,detail_mobile_2_5],
        'nav_img': detail_small_2_1,
        'nav_detail_img': nav_detail_img_2,
        'list_img': [list_main_2_1, list_main_2_2],
        'hard_type_img' : hard_type_S,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814317174',
        'firstPrice': '3,750,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    }, {
        'no': 3,
        'title': '라비아 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_3,
        'detail_img_big': [detail_big_3_1, detail_big_3_2, detail_big_3_3, detail_big_3_4, detail_big_3_5],
        'detail_img_small': [detail_small_3_1, detail_small_3_2, detail_small_3_3, detail_small_3_4, detail_small_3_5],
        'detail_mobile_img' : [detail_mobile_3_1,detail_mobile_3_2,detail_mobile_3_3,detail_mobile_3_4,detail_mobile_3_5],
        'nav_img': detail_small_3_1,
        'nav_detail_img': nav_detail_img_3,
        'list_img': [list_main_3_1, list_main_3_2],
        'hard_type_img' : hard_type_SM,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814554505',
        'firstPrice': '2,750,000',
        'price': {
            K: '2,550,000',
            Q: '2,250,000',
            SS: '1,950,000'
        }
    }, {
        'no': 4,
        'title': '졸리 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_4,
        'detail_img_big': [detail_big_4_1, detail_big_4_2, detail_big_4_3, detail_big_4_4, detail_big_4_5],
        'detail_img_small': [detail_small_4_1, detail_small_4_2, detail_small_4_3, detail_small_4_4, detail_small_4_5],
        'detail_mobile_img' : [detail_mobile_4_1,detail_mobile_4_2,detail_mobile_4_3,detail_mobile_4_4,detail_mobile_4_5],
        'nav_img': detail_small_4_1,
        'nav_detail_img': nav_detail_img_4,
        'list_img': [list_main_4_1, list_main_4_2],
        'hard_type_img' : hard_type_M,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814479626',
        'firstPrice': '2,400,000',
        'price': {
            K: '2,200,000',
            Q: '1,900,000',
            SS: '1,600,000'
        }
    }, {
        'no': 5,
        'title': '베르디 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_5,
        'detail_img_big': [detail_big_5_1, detail_big_5_2, detail_big_5_3, detail_big_5_4, detail_big_5_5],
        'detail_img_small': [detail_small_5_1, detail_small_5_2, detail_small_5_3, detail_small_5_4, detail_small_5_5],
        'detail_mobile_img' : [detail_mobile_5_1,detail_mobile_5_2,detail_mobile_5_3,detail_mobile_5_4,detail_mobile_5_5],
        'nav_img': detail_small_5_1,
        'nav_detail_img': nav_detail_img_5,
        'list_img': [list_main_5_1, list_main_5_2],
        'hard_type_img' : hard_type_MH,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814536618',
        'firstPrice': '3,750,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    },
    {
        'no': 6,
        'title': '로즈 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_6,
        'detail_img_big': [detail_big_6_1, detail_big_6_2, detail_big_6_3, detail_big_6_4, detail_big_6_5],
        'detail_img_small': [detail_small_6_1, detail_small_6_2, detail_small_6_3, detail_small_6_4, detail_small_6_5],
        'detail_mobile_img' : [detail_mobile_6_1,detail_mobile_6_2,detail_mobile_6_3,detail_mobile_6_4,detail_mobile_6_5],
        'nav_img': detail_small_6_1,
        'nav_detail_img': nav_detail_img_6,
        'list_img': [list_main_6_1, list_main_6_2],
        'hard_type_img' : hard_type_MH,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814511480',
        'firstPrice': '2,400,000',
        'price': {
            K: '2,200,000',
            Q: '1,900,000',
            SS: '1,600,000'
        }
    },
    {
        'no': 7,
        'title': '에로스 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'sleep_maker' : true,
        'detail_main_img' : [detail_main_img_7,detail_main_img_7_1,detail_main_img_7_2,sleep_maker_movie_main,sleep_maker_movie_gif_1,sleep_maker_movie_gif_2],
        'detail_img_big': [detail_big_7_1, detail_big_7_2, detail_big_7_3, detail_big_7_4, detail_big_7_5],
        'detail_img_small': [detail_small_7_1, detail_small_7_2, detail_small_7_3, detail_small_7_4, detail_small_7_5],
        'detail_mobile_img' : [detail_mobile_7_1,detail_mobile_7_2,detail_mobile_7_3,detail_mobile_7_4,detail_mobile_7_5],
        'nav_img': detail_small_7_1,
        'nav_detail_img': nav_detail_img_7,
        'list_img': [list_main_7_1, list_main_7_2],
        'hard_type_img' : hard_type_H,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/5814420413',
        'firstPrice': '3,750,000',
        'price': {
            K: '3,550,000',
            Q: '3,250,000',
            SS: '2,950,000'
        }
    },
    {
        'no': 8,
        'title': '오로라 메모리폼 매직 매트리스',
        'description': '스프링의 장점과 폼의 장점이 모두 반영된 하이브리드 매트리스입니다. 개인에게 최적화된 수면을 위해 맞춤형 제작이 가능합니다.',
        'detail_main_img' : detail_main_img_8,
        'detail_img_big': [detail_big_8_1, detail_big_8_2, detail_big_8_3, detail_big_8_4, detail_big_8_5],
        'detail_img_small': [detail_big_8_1, detail_big_8_2, detail_big_8_3, detail_big_8_4, detail_big_8_5],
        'detail_mobile_img' : [detail_mobile_6_1,detail_mobile_6_2,detail_mobile_6_3,detail_mobile_6_4,detail_mobile_6_5],
        'nav_img': detail_small_6_1,
        'nav_detail_img': nav_detail_img_6,
        'list_img': [list_main_8_1, list_main_8_2],
        'hard_type_img' : hard_type_MH,
        'naverAddress' : 'https://smartstore.naver.com/cloudmagic/products/10576802755',
        'firstPrice': '9,900,000',
        'price': {
            K: '9,900,000',
            Q: '9,900,000',
            SS: '9,900,000'
        }
    },
]

import React from "react";
import {Col, Row} from "reactstrap";
import notice_img from '../../asset/img/landing/notice_img.svg';
import more_btn from '../../asset/img/landing/plus.svg';
import {Link} from "react-router-dom";
import noticeList from '../../data/notice'
const LandingNotice = () => {

    return (
        <div style={{background : '#f7f7f7'}}>
        <div className='landing_notice_wrapper container'>
            <div className='landing_notice_header'>
                <Row className='w-100' noGutters>
                    <Col xs={6}>
                        <h2>공지사항</h2>
                    </Col>
                    <Col xs={6} className='landing_header_right'>
                        <Link to={'/customer'}>
                            <span>더보기</span><img src={more_btn}/>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='landing_notice_content mt-3'>
                {noticeList.map((item,index) => {
                    return (
                        <Row key={index} className='g-0 landing_notice_item'>
                            <Link to={{
                                pathname: "/notice",
                                state: {index: item.index}
                            }}>
                                <Col xs={12}>
                                    <Row className='notice_top_txt g-0'>
                                        <Col xs={12}>
                                            <h3>
                                                {item.title}
                                            </h3>
                                        </Col>
                                        <Col xs={12}>
                                <span>
                                    {item.date}
                                    </span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className='mt-3'>
                                    <img style={{width : '100%'}} src={notice_img}/>
                                </Col>
                            </Link>
                        </Row>
                    )
                })}


            </div>
        </div>
        </div>
    )
}

export default LandingNotice;

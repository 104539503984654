import React from 'react';
import {Col, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import KkaMap from "./KkaMap";
import closeImg from "../../asset/img/close.svg";
import showRoomInfo from '../../data/showRoom';

type ShowRoomModalProps = {
    toggle: () => void,
    modal: boolean,
    index: number
}


const ShowRoomModal = ({toggle, modal, index}: ShowRoomModalProps) => {


    return (
        <div>
            <Modal isOpen={modal} toggle={toggle} className='showRoom_modal_wrapper'>
                <ModalHeader> <img src={closeImg} onClick={toggle}/> </ModalHeader>
                <ModalBody>
                    <div className='showRoom_info_item'>
                        <div className='text-center p-4'>
                            <h2>{showRoomInfo[index].title}</h2>
                        </div>
                        <Row className='item_row g-0'>
                            <Col className='showRoom_map' xs={6}>
                                <KkaMap lat={showRoomInfo[index].lat} lng={showRoomInfo[index].lng}/>
                            </Col>
                            <Col className='showRoom_txt' xs={4}>
                                <p>
                                    <strong>주소</strong><br/>
                                    {showRoomInfo[index].address}
                                </p>
                                <p className='mt-4'>
                                    <strong>영업시간</strong><br/>
                                    평일, 주말<br/>
                                    {showRoomInfo[index].time}
                                </p>
                                <p>
                                    <strong>연락처</strong><br/>
                                    <span className='main_color'>
                                        {showRoomInfo[index].phone}
                                    </span>
                                </p>
                            </Col>

                        </Row>
                    </div>
                </ModalBody>

            </Modal>
        </div>
    );
}
ShowRoomModal.defaultProps = {
    modal: false
}
export default ShowRoomModal;

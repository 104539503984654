import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';
import testImg from '../../asset/img/landing/landing_new_img2.svg'
import mainImg1 from '../../asset/img/landing/main_landing_img_1.png';
import mainImg2 from '../../asset/img/landing/main_landing_img_2.png';

function LandingCarousel() {
    var items = [
        {
            img: mainImg1
        },
        {
            img: mainImg2
        }
    ]

    return (
        <div className=' landing_carousel_wrapper'>
            <Carousel
                autoPlay={true}
                interval={5000}
                infiniteLoop={true}
                showThumbs={false}
                showStatus={false}
            >
                {
                    items.map((item, i) =>
                        <div key={i} className='landing_carousel_item'>
                            <img key={i} src={item.img} style={{width: '100%', height: '100%'}}/>
                            <div className={`carousel_txt_wrapper item_${i}`}>
                                <h2>
                                    Cloud Magic
                                </h2>
                                <h4>편안한 하루의 마무리 ,</h4>
                                <h4>클라우드 매직과 함께하세요</h4>
                            </div>
                        </div>)
                }
            </Carousel>
        </div>
    )
}

export default LandingCarousel;

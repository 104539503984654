import React, {useState, useEffect} from 'react';
import {TabContent, TabPane, Nav, NavItem, NavLink, Button, UncontrolledCollapse, Row, Col} from 'reactstrap';
import classnames from 'classnames';
import double_arrow from "../../asset/img/double_arrow.png";
import arrow from "../../asset/img/arrow.png";
import {Link} from "react-router-dom";
import faqList from '../../data/faq'
import noticeList from '../../data/notice';
import faqArrow from '../../asset/img/customer/faq_arrow.svg';
import {useLocation} from 'react-router-dom';

const Customer = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('1');
    const [toggleFaq, setToggleFaq] = useState([]);
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    useEffect(() => {
        if (location.state) {
            setActiveTab(location.state.tabNo)
        }
    }, [location]);

    const faqHandler = (index) => {
        if (toggleFaq.indexOf(index) !== -1) {
            setToggleFaq(toggleFaq.filter(item => item !== index))
        } else {
            setToggleFaq([...toggleFaq, index]);
        }

    }
    return (
        <div className='container'>
            <div className='customer_wrapper'>
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '1'})}
                            onClick={() => {
                                toggle('1');
                            }}
                        >
                            공지사항
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({active: activeTab === '2'})}
                            onClick={() => {
                                toggle('2');
                            }}
                        >
                            FAQ
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className='customer_notice_wrapper'>
                            <Row>
                                <Col xs="12" className='text-center notice_title'>
                                    <h1>공지사항</h1>
                                    <h4>클라우드매직의 소식을 확인해 보세요.</h4>
                                </Col>
                                <Col sm="12">
                                    <ul className='ul_notice'>
                                        {noticeList.map((notice, index) => {
                                            return (
                                                <li key={index}>
                                                    <div className='notice_item_no'>
                                                        <div className='notice_no_back'>
                                                            <span>{notice.index}</span>
                                                        </div>
                                                    </div>
                                                    <div className='notice_item_title'>
                                                        <Link to={{
                                                            pathname: "/notice",
                                                            state: {index: notice.index}
                                                        }}>
                                                            <span>{notice.title}</span>
                                                        </Link>
                                                    </div>
                                                    <div className='notice_item_date'>
                                                        <span>{notice.date}</span>
                                                    </div>
                                                </li>
                                            )
                                        })}


                                    </ul>
                                </Col>
                                <Col xs={12} className='mt-4'>
                                    <div className='pagination_wrapper'>
                                        <button className='left_page_btn'><img src={double_arrow}/></button>
                                        <button className='left_page_btn'><img src={arrow}/></button>
                                        <button><span>1</span></button>
                                        <button><img src={arrow}/></button>
                                        <button><img src={double_arrow}/></button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div className='customer_faq_wrapper'>
                            <Row>
                                <Col xs="12" className='text-center faq_title'>
                                    <h1>FAQ</h1>
                                    <h4>자주 문의하시는 질문들을 확인해 보세요.</h4>
                                </Col>
                                <Col xs='12' className='faq_section'>
                                    <div className='faq_item_wrapper'>
                                        <h4>제품</h4>
                                        <ul className='ul_faq'>
                                            {faqList['product'].map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Button onClick={() => faqHandler(item.index)} id={`product_toggler_1_${index}`}>
                                                            <div className='faq_item'>
                                                                <span>{item.title}</span>
                                                                <img className={toggleFaq.includes(item.index) ? 'rotate_arrow' : ''} src={faqArrow}/>
                                                            </div>

                                                        </Button>
                                                        <UncontrolledCollapse toggler={`product_toggler_1_${index}`}>
                                                            <div className='toggle_wrapper'>
                                                                <div className='toggle_content_wrapper'>
                                                                    <p>
                                                                        {item.content
                                                                            .split('\n')
                                                                            .map((line, index) => {
                                                                                return (
                                                                                    <span key={index}>{line}<br/></span>
                                                                                )
                                                                            })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </UncontrolledCollapse>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                </Col>
                                <Col xs='12' className='faq_section'>
                                    <div className='faq_item_wrapper'>
                                        <h4>교환 반품 및 환불</h4>
                                        <ul className='ul_faq'>
                                            {faqList['change'].map((item, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Button onClick={() => faqHandler(item.index)} id={`change_toggler_${index}`}>
                                                            <div className='faq_item'>
                                                                <span>{item.title}</span>
                                                                <img className={toggleFaq.includes(item.index) ? 'rotate_arrow' : ''} src={faqArrow}/>
                                                            </div>
                                                        </Button>
                                                        <UncontrolledCollapse toggler={`change_toggler_${index}`}>
                                                            <div className='toggle_wrapper'>
                                                                <div className='toggle_content_wrapper'>
                                                                    <p>
                                                                        {item.content
                                                                            .split('\n')
                                                                            .map((line, index) => {
                                                                                return (
                                                                                    <span key={index}>{line}<br/></span>
                                                                                )
                                                                            })}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </UncontrolledCollapse>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </div>
                                </Col>
                                {/*<Col xs='12' className='faq_section'>*/}
                                {/*    <div className='faq_item_wrapper'>*/}
                                {/*        <h4>회원 정보</h4>*/}
                                {/*        <ul className='ul_faq'>*/}
                                {/*            {faqList['user'].map((item, index) => {*/}
                                {/*                return (*/}
                                {/*                    <li key={index}>*/}
                                {/*                        <Button onClick={() => faqHandler(item.index)} id={`user_toggler_${index}`}>*/}
                                {/*                            <div className='faq_item'>*/}
                                {/*                                <span>{item.title}</span>*/}
                                {/*                                <img className={toggleFaq.includes(item.index) ? 'rotate_arrow' : ''} src={faqArrow}/>*/}
                                {/*                            </div>*/}
                                {/*                        </Button>*/}
                                {/*                        <UncontrolledCollapse toggler={`user_toggler_${index}`}>*/}
                                {/*                            <div className='toggle_wrapper'>*/}
                                {/*                                <div className='toggle_content_wrapper'>*/}
                                {/*                                    <p>*/}
                                {/*                                        {item.content*/}
                                {/*                                            .split('\n')*/}
                                {/*                                            .map((line, index) => {*/}
                                {/*                                                return (*/}
                                {/*                                                    <span key={index}>{line}<br/></span>*/}
                                {/*                                                )*/}
                                {/*                                            })}*/}
                                {/*                                    </p>*/}
                                {/*                                </div>*/}
                                {/*                            </div>*/}
                                {/*                        </UncontrolledCollapse>*/}
                                {/*                    </li>*/}
                                {/*                )*/}
                                {/*            })}*/}

                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</Col>*/}
                            </Row>
                        </div>
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
}

export default Customer;

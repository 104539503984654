import React from "react";
import part_num_1 from '../../asset/img/brandStory/part_1/one.png';
import part_1_img_1 from '../../asset/img/brandStory/part_1/part_1_img.png';
import part_1_img_2 from '../../asset/img/brandStory/part_1/part_1_img_2.png';
import part_1_img_3 from '../../asset/img/brandStory/part_1/part_1_img_3.png';
import part_num_2 from '../../asset/img/brandStory/part_1/two.png';
import part_2_why from '../../asset/img/brandStory/part_2/part_2_img_why.png';
import part_2_bed_1 from '../../asset/img/brandStory/part_2/part_2_bed_1.png';
import part_2_bed_2 from '../../asset/img/brandStory/part_2/part_2_bed_2.png';
import part_2_bed_3 from '../../asset/img/brandStory/part_2/part_2_bed_3.png';
import part_2_bed_4 from '../../asset/img/brandStory/part_2/part_2_bed_4.png';
import part_2_bed_5 from '../../asset/img/brandStory/part_2/part_2_bed_5.png';
import part_2_bt_1 from '../../asset/img/brandStory/part_2/part_2_bt_1.png';
import part_2_bt_2 from '../../asset/img/brandStory/part_2/part_2_bt_2.png';
import part_2_bt_3 from '../../asset/img/brandStory/part_2/part_2_bt_3.png';
import circle_img from '../../asset/img/brandStory/part_2/circle_img.png';
import no_img from '../../asset/img/brandStory/part_2/no_img.png';
import useScrollFadeIn from "../hooks/useScrollFadeIn";

const BrandStory = () => {
    const animatedItem = useScrollFadeIn('up', 1, 1);
    const animatedItem2 = useScrollFadeIn('up', 1, 1);
    const animatedItem3 = useScrollFadeIn('up', 1, 2);
    const animatedItem4 = useScrollFadeIn('up', 1, 2);
    return (
        <div className='brand_story_wrapper container'>
            <div className='top_txt_wrapper'>
                <span>Cloud Magic</span>
                <h3>BRAND STORY</h3>
                <hr/>
                <span>" 포근한 구름 위에 잠들다 "</span>
            </div>
            <div className='part_1_wrapper'>
                <div className='part_title'>
                    <span>PART</span>
                    <img src={part_num_1}/>
                </div>
                <div className='part_item'>
                    <div className='left_sec'>
                        <h2><span>1</span>. High Quailty</h2>
                        <hr/>
                        <p>
                            매트리스 기획, 개발, 생산, 발송 까지
                            자체적으로 진행을 하고 있으며
                            고객에게 최고의 품질을 약속합니다.
                        </p>
                    </div>
                    <div className='right_sec'>
                        <img src={part_1_img_1}/>
                    </div>
                </div>
                <div className='part_item_re'>
                    <div className='left_sec'>
                        <img src={part_1_img_2}/>
                    </div>
                    <div className='right_sec'>
                        <h2><span>2</span>. SAFETY</h2>
                        <hr/>
                        <p>
                            공인된 연구 기관을 통해 라돈과 방사선으로부터
                            안전함을 검증받은 친환경 매트리스로서
                            안전한 잠자리를 약속드립니다.
                        </p>
                    </div>
                </div>
                <div className='part_item'>
                    <div className='left_sec'>
                        <h2><span>3</span>. COMFORT</h2>
                        <hr/>
                        <p>
                            딱딱한 매트리스부터 푹신한 매트리스까지
                            나에게 딱 맞는 매트리스를 선택해
                            편안한 수면을 느껴보세요.
                        </p>
                    </div>
                    <div className='right_sec'>
                        <img src={part_1_img_3}/>
                    </div>
                </div>
            </div>
            <div className='part_2_wrapper'>
                <div className='part_title'>
                    <span>PART</span>
                    <img src={part_num_2}/>
                    <h2 className='mt-4'>왜 클라우드 매직이여야 하는가?</h2>
                </div>
                <div className='part_content_wrapper'>
                    <div className='left_sec'>
                        <table>
                            <tr>
                                <td>
                                    <div className='left_img_wrapper'>
                                        <img src={part_2_bed_1}/>
                                            <img {...animatedItem} className='no_img' src={no_img}/>
                                        <span>본넬 스프링 매트리스</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='left_img_wrapper'>
                                        <img src={part_2_bed_2}/>
                                            <img {...animatedItem2} className='no_img' src={no_img}/>
                                        <span>포켓 스프링 매트리스</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    {/*<img id='backache_img' src={part_2_bed_3}/>*/}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='left_img_wrapper'>
                                        <img src={part_2_bed_4}/>
                                            <img {...animatedItem3} className='circle_img' src={circle_img}/>
                                        <span>메모리폼 매트리스</span>
                                    </div>
                                </td>
                                <td>
                                    <div className='left_img_wrapper'>
                                        <img src={part_2_bed_5}/>
                                            <img {...animatedItem4} className='circle_img' src={circle_img}/>
                                        <span>하이브리드 매트리스</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className='right_sec'>
                        <div className='why_icon_wrapper'>
                            <img src={part_2_why}/>
                        </div>
                        <div className='part_content_item'>
                            <div className='part_content_title'>
                                <div className='color_box'/>
                                <span>잠을 자도 <strong>피곤했던 경험</strong>이 있으신가요?</span>
                            </div>
                            <div className='part_content_box'>
                                <p>
                                    잠을 자도 피곤하다면 매트리스가 나에게
                                    맞지 않는 것입니다. 클라우드 매직 매트리스로
                                    <strong>수면 건강</strong>을 챙겨보세요.
                                </p>
                            </div>
                        </div>
                        <div className='part_content_item'>
                            <div className='part_content_title'>
                                <div className='color_box'/>
                                <span>아직도 <strong>스프링</strong> 이나 <strong>코일 매트리스</strong>를 사용하고 계신가요?</span>
                            </div>
                            <div className='part_content_box'>
                                <p>
                                    스프링 매트리스는 매트리스의 경도가
                                    높아 몸의 굴곡을 따라 완전히 밀착시켜주지
                                    못해 허리통증이나 불편함을 느낄 수 있습니다.
                                    클라우드 매직은 꾸준한 기술 개발을 통해
                                    여러분에게 <strong>편안한 숙면을 제공</strong>합니다.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='part_2_bt_wrapper'>
                    <div className='part_2_bt_item'>
                        <div className='bt_title'>
                            <span>사용자 체형에<br/> 맞는 핏을 제공합니다.</span>
                        </div>
                        <div className='bt_content' style={{backgroundImage: `url(${part_2_bt_1})`}}>
                            <p>누웠을 때 열과 압력에 반응하여 표면이<br/>
                                체중을 고르게 분산시킴으로서<br/>
                                안락한 느낌을 받으실 수 있습니다.</p>
                        </div>
                    </div>
                    <div className='part_2_bt_item'>
                        <div className='bt_title'>
                            <span>수면에 집중하도록 <br/>도와줍니다.</span>
                        </div>
                        <img id='bt_2_img' src={part_2_bt_2}/>
                        <p id='bt_2_content'>
                            표면의 움직임을<br/>
                            흡수하여 파트너가<br/>
                            침대에서 움직이더라도<br/>
                            수면 방해를 받지 않습니다.</p>
                    </div>
                    <div className='part_2_bt_item'>
                        <div className='bt_title'>
                            <span>친환경적인 소재만을 <br/>사용합니다. </span>
                        </div>
                        <div className='bt_content' style={{backgroundImage: `url(${part_2_bt_3})`}}>
                            <p id='bt_3_content'>누웠을 때 열과 압력에 반응하여 표면이<br/>
                                체중을 고르게 분산시킴으로서<br/>
                                안락한 느낌을 받으실 수 있습니다.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default BrandStory;

import React, { useEffect } from 'react'
const { kakao } = window;

const KkaMap=({lat,lng})=>{
    useEffect(()=>{
        const container = document.getElementById('map');
        const options = {
            center: new kakao.maps.LatLng(lat, lng),
            level: 3
        };
        const map = new kakao.maps.Map(container, options);

        let markerPosition = new kakao.maps.LatLng(
            lat,
            lng
        );

        // 마커를 생성
        let marker = new kakao.maps.Marker({
            position: markerPosition,
        });

        // 마커를 지도 위에 표시
        marker.setMap(map);
    },[])


    return (
        <div>
            <div id="map" ></div>
        </div>
    )
}

export default KkaMap;

import React, {useEffect, useState} from "react";
import {Button, Col, Label, Row} from "reactstrap";
import mattress from "../../data/mattress";
import basket_img from '../../asset/img/detail/shopping-bag.svg';
import DetailTab from "./DetailTab";
import {
    BrowserView,
    MobileView,
    isMobile
} from "react-device-detect";
import DetailCarousel from "./DetailCarousel";


const Detail = ({match, history}) => {
    const [mainImg, setMainImg] = useState(0);
    const [value, setValue] = useState('Q');
    const [detailNo, setDetailNo] = useState(0);
    const [selectSize, setSelectSize] = useState([]);
    useEffect(() => {
        if (match.params.hasOwnProperty('no')) {
            setDetailNo(match.params.no - 1);
        } else {
            alert('잘못된 접근 입니다.')
            history.push('/')
        }
    }, [match.params])
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const addSizeItem = (size) => {
        const alreadyAdd = selectSize.some(item => item.size === size);
        const matPrice = parseInt(mattress[detailNo].price[size].replace(/,/gi, ""));
        if (!alreadyAdd) {
            setSelectSize([...selectSize, {size: size, count: 1, price: matPrice}])
        } else {
            alert('이미 추가된 상품 입니다.')
        }
    }

    const plusCountItem = (size, count) => {

        const matPrice = parseInt(mattress[detailNo].price[size].replace(/,/gi, ""));
        const sizeIndex = selectSize.findIndex(order => order.size === size);
        selectSize[sizeIndex].count = count + 1;
        selectSize[sizeIndex].price += matPrice;
        setSelectSize([...selectSize]);
    }
    const minusCountItem = (size, count) => {

        if (count - 1 <= 0) {
            alert('최소 주문 수량은 1개 이상입니다.')
        } else {
            const matPrice = parseInt(mattress[detailNo].price[size].replace(/,/gi, ""));
            const sizeIndex = selectSize.findIndex(order => order.size === size);
            selectSize[sizeIndex].count = count - 1;
            selectSize[sizeIndex].price -= matPrice;
            setSelectSize([...selectSize]);
        }
    }
    const cancelItem = (size) => {
        const sizeIndex = selectSize.findIndex(order => order.size === size);
        selectSize.splice(sizeIndex, 1);
        setSelectSize([...selectSize]);
    }
    return (
        <div className='detail_wrapper container'>
            <Row className='g-0'>
                <Col xs={12} className='detail_top_wrapper'>
                    <Row className='g-0 detail_img_container'>
                        <Col xs={8} className='detail_img_container'>
                            <BrowserView>
                                <div className='detail_img_wrapper'>
                                    <div className='big_img_wrapper'>
                                        <img src={mattress[detailNo].detail_img_big[mainImg]}/>
                                    </div>
                                    <div className='small_img_wrapper'>
                                        {mattress[detailNo].detail_img_small.map((img, index) => {
                                            return (
                                                <img className={mainImg === index ? 'detail_img_border' : ''} onMouseEnter={() => setMainImg(index)} src={img}/>
                                            )
                                        })}
                                    </div>
                                </div>
                            </BrowserView>
                            <MobileView>
                                <DetailCarousel detailNo={detailNo} mainImg={mainImg}/>
                            </MobileView>

                        </Col>
                        <Col xs={4} className='detail_info_wrapper'>
                            {mattress[detailNo].title.split('\n')
                                .map((line, index) => {
                                    return (
                                        <h2 key={index}>{line}<br/></h2>
                                    )
                                })}
                            <p>{mattress[detailNo].description}</p>
                            <div className='detail_info_price'>
                                <span className='price_per'>{Math.ceil(((parseInt(mattress[detailNo].firstPrice) - parseInt(mattress[detailNo].price[value])) / parseInt(mattress[detailNo].firstPrice)) * 100)}%</span> <span>{mattress[detailNo].firstPrice}원</span>
                                <h3>{mattress[detailNo].price[value]}원</h3>
                            </div>
                            <div className='detail_info_shipping'>
                                <label>배송비</label>
                                <span>무료배송</span>
                            </div>
                            {/*<div className='detail_info_size'>*/}
                            {/*    <FormControl component="fieldset">*/}
                            {/*        <FormLabel component="legend">사이즈</FormLabel>*/}
                            {/*        <RadioGroup style={{flexDirection: 'row'}} aria-label="size" name="size" value={value} onChange={handleChange}>*/}
                            {/*            <FormControlLabel value="SS" control={<ColorRadio/>} label="SS"/>*/}
                            {/*            <FormControlLabel value="Q" control={<ColorRadio/>} label="Q"/>*/}
                            {/*            <FormControlLabel value="K" control={<ColorRadio/>} label="K"/>*/}
                            {/*        </RadioGroup>*/}
                            {/*    </FormControl>*/}
                            {/*</div>*/}
                            <div className='detail_info_size'>
                                <Label>사이즈</Label>
                                <Button onClick={() => addSizeItem('SS')}>SS 1100</Button>
                                <Button onClick={() => addSizeItem('Q')}>Q 1500</Button>
                                <Button onClick={() => addSizeItem('K')}>K 1600</Button>
                            </div>
                            <hr className='w-100'/>
                            <div className='order_list_wrapper'>
                                {selectSize.map(order => {
                                    return (
                                        <div className='order_list_item'>
                                            <div className='order_info'>
                                                <span id='order_title_info'>{mattress[detailNo].title}</span>
                                                <span id='order_size_info'>{order.size === 'SS' ? '슈퍼싱글 (1100)' : order.size === 'Q' ? '퀸 (1500)' : '킹 (1600)'}</span>
                                            </div>
                                            <div className='order_count'>
                                                <button onClick={() => plusCountItem(order.size, order.count)} className='count_btn'>+</button>
                                                <span className='count_txt'>
                                                {order.count}
                                                    </span>
                                                <button onClick={() => minusCountItem(order.size, order.count)} className='count_btn'>-</button>
                                            </div>
                                            <div className='order_list_cancel_wrapper'>
                                                <button onClick={() => cancelItem(order.size)} className='order_cancel_btn'>&times;</button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='detail_info_btn_wrapper'>
                                <div className='last_price_wrapper'>
                                    <span>총 상품금액 <span className='main_color fs-1'>
                                        {selectSize.length === 0 ?
                                            0 :
                                            selectSize.map(order => order.price).reduce((total, currentValue) => total + currentValue, 0)}
                                    </span>원</span>
                                </div>
                                <div className='btn_container'>
                                    <Button onClick={() => window.location.href = mattress[detailNo].naverAddress}><img src={basket_img}/> </Button>
                                    <Button onClick={() => window.location.href = mattress[detailNo].naverAddress}>구매하기</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} className='detail_bottom_wrapper'>
                    <DetailTab detailNo={detailNo}/>
                </Col>
            </Row>
        </div>
    )
}


export default Detail;

import React, {useEffect, useState} from "react";

import {useLocation} from "react-router-dom";
import LandingNav from "./LandingNav";
import DefaultNav from "./DefaultNav";

const NavbarTop = () => {
    const location = useLocation();
    const [isLanding, setIsLanding] = useState(false);

    useEffect(() => {
        if (location.pathname === '/') setIsLanding(true);
        else setIsLanding(false);
    }, [location.pathname]);

    return (
        <>
            {
                isLanding ?
                    <LandingNav/>
                    :
                    <DefaultNav/>
            }
        </>
    )
        ;
}


export default NavbarTop;

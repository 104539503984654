import React from 'react';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import agreement from "../../data/agreement";

const TermModal = ({modal, setModal, seq}) => {

    const toggle = () => setModal(!modal)

    return (
        <div className="w-100 text-center">
            <Modal size='lg' centered aria-labelledby="contained-modal-title-vcenter" isOpen={modal} toggle={toggle}>
                <ModalHeader className='text-center w-100 justify-content-center'>{seq === 0 ? '이용약관' : '개인정보 처리방침 약관'}</ModalHeader>
                <ModalBody>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-11  text-center" style={{backgroundColor: "#f7f7f7", height : '300px' , overflow : 'scroll'}}>
                            {
                                agreement
                                    .map(item => item.description)[seq]
                                    .split('\n')
                                    .map((line, index) => {
                                        return (
                                            <span key={index}>{line}<br/></span>
                                        )
                                    })
                            }
                        </div>
                        <div className="enter_room_btn_wrapper col-12 text-center mt-4">
                            <button className="btn agree_btn" onClick={toggle}>확인</button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

TermModal.propTypes = {
    setModal: PropTypes.func.isRequired,
    modal: PropTypes.bool,
    // 0 - 이용약관, 1 - 개인정보 처리방침
    seq: PropTypes.oneOf([0, 1]).isRequired
};

TermModal.defaultProps = {
    modal: false
};

export default TermModal;

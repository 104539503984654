import React, {useState} from "react";
import showRoomCloudImg from "../../asset/img/showRoom_top_img.png";
import {useHistory} from "react-router-dom";
import ShowRoomModal from "./ShowRoomModal";

const ShowRoom = () => {
    const history = useHistory();
    const [modal, setModal] = useState(false);
    const [index, setIndex] = useState(0);
    const toggle = () => setModal(!modal);

    return (
        <div className='showRoom_container'>
            <div className='showRoom_topImg_wrapper'>
                <div className='showRoom_top_label text-center'>
                    <h2>클라우드매직 직영매장</h2>
                    <span>클라우드 매직에 전 매트리스를 체험해볼 수 있는 직영매장!</span>
                    <span>구름과 같은 매트리스를 직접 경험해보세요.</span>
                </div>
                <img src={showRoomCloudImg}/>
            </div>
            <div className='showRoom_list_wrapper container'>
                <div className='showRoom_list_item'>
                    <div className='showRoom_name'>
                        <h4>순천점</h4>
                    </div>
                    <div className='showRoom_address'>
                        <span>전라남도 순천시 해룡면 여순로 1513</span>
                    </div>
                    <div className='showRoom_phoneNum'>
                        <span>061-723-7747</span>
                    </div>
                    <div className='showRoom_modal_btn'>
                        <button onClick={
                            () => {
                                toggle();
                                setIndex(0);
                            }
                        }>매장보기</button>
                    </div>
                </div>
                <div className='showRoom_list_item'>
                    <div className='showRoom_name'>
                        <h4>포천점</h4>
                    </div>
                    <div className='showRoom_address'>
                        <span>경기 포천시 호국로 1153-9</span>
                    </div>
                    <div className='showRoom_phoneNum'>
                        <span>031-542-9475</span>
                    </div>
                    <div className='showRoom_modal_btn'>
                        <button onClick={
                            () => {
                                toggle();
                                setIndex(1);
                            }
                        }>매장보기</button>
                    </div>
                </div>

            </div>
            <ShowRoomModal modal={modal} toggle={toggle} index={index}/>
        </div>
    )
}
export default ShowRoom;
